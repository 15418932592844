import React from "react";
import Select from "react-select";

const Filters = ({
  country,
  countryOptions,
  setCountry,
  resetCountry,
  selectedAssignedOption,
  optionsAssignedUser,
  handleUserAssignedChange,
  resetAssignedTo,
  isDropdownOpen,
  setIsDropdownOpen,
  selectedOptions,
  options,
  handleSelectAll,
  handleSelectNone,
  handleOptionChange,
  selectRef,
  selectRefDeleted,
  isDeleted,
  isDeletedDropdownOpen,
  setIsDeletedDropdownOpen,
  handleSelectDeleted,
  date,
  setDate,
  userId,
  setUserId,
  userName,
  setUserName,
}) => {
  return (
    <div className="row mx-2" style={{ paddingRight: "50px" }}>
      <div className="col-12 col-md-auto">
        <span className="drawer_span">Country</span>
        <div className="custom-select-container-content" style={{ display: "flex", alignItems: "center" }}>
          <Select
            isMulti={false}
            name="countries"
            value={countryOptions?.find((option) => option?.label === country) || null}
            options={countryOptions}
            onChange={(selectedOption) => setCountry(selectedOption ? selectedOption.label : null)}
            className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
            classNamePrefix="select"
          />
          <b onClick={resetCountry} style={{ marginLeft: "10px", cursor: "pointer" }}>
            <i className="bi bi-x-circle-fill"></i>
          </b>
        </div>
      </div>

      <div className="col-12 col-md-auto">
        <span className="drawer_span">Assigned To</span>
        <div className="custom-select-container-content" style={{ display: "flex", alignItems: "center" }}>
          <Select
            isMulti={false}
            className="drawer_input"
            menuPosition={"fixed"}
            value={selectedAssignedOption || null}
            options={optionsAssignedUser}
            onChange={handleUserAssignedChange}
            classNamePrefix="select"
            components={{ IndicatorSeparator: () => null }}
            filterOption={(option, inputValue) =>
              option.data.label.props.children[1].props.children
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
          />
          <b onClick={resetAssignedTo} style={{ marginLeft: "10px", cursor: "pointer" }}>
            <i className="bi bi-x-circle-fill"></i>
          </b>
        </div>
      </div>

      <div className="col-12 col-md-auto" ref={selectRef}>
        <span className="drawer_span">Status</span>
        <div className="custom-select-container-select">
          <div className="selected-items" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {selectedOptions.length > 0
              ? selectedOptions
                  ?.map((option) => options.find((o) => o.value === option)?.label)
                  .join(", ")
              : "Select Status"}
          </div>
          {isDropdownOpen && (
            <div className="dropdown-custom">
              <ul className="checkbox-list">
                <li>
                  <label>
                    <input type="checkbox" checked={selectedOptions.length === options.length} onChange={handleSelectAll} />
                    Select All
                  </label>
                </li>
                <li>
                  <label>
                    <input type="checkbox" checked={selectedOptions.length === 0} onChange={handleSelectNone} />
                    Select None
                  </label>
                </li>
                {options.map((option) => (
                  <li key={option.value}>
                    <label>
                      <input type="checkbox" value={option.value} checked={selectedOptions.includes(option.value)} onChange={() => handleOptionChange(option.value)} />
                      {option.label}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className="col-12 col-md-auto" ref={selectRefDeleted}>
        <span className="drawer_span">Deleted</span>
        <div className="custom-select-container-select">
          <div className="selected-items" onClick={() => setIsDeletedDropdownOpen(!isDeletedDropdownOpen)}>
            Show Deleted
          </div>
          {isDeletedDropdownOpen && (
            <div className="dropdown-custom">
              <ul className="checkbox-list">
                <li>
                  <label>
                    <input type="checkbox" checked={isDeleted === "isDeleted=true"} onChange={handleSelectDeleted} />
                    Show Deleted
                  </label>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className="col-12 col-md-auto">
        <span className="drawer_span">Date</span>
        <div className="custom-select-container-select">
          <input type="date" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} />
        </div>
      </div>

      <div className="col-12 col-md-auto">
        <span className="drawer_span">User Id</span>
        <div className="custom-select-container">
          <input type="text" className="form-control" value={userId} onChange={(e) => setUserId(e.target.value)} />
        </div>
      </div>

      <div className="col-12 col-md-auto">
        <span className="drawer_span">User Name</span>
        <div className="custom-select-container">
          <input type="text" className="form-control" value={userName} onChange={(e) => setUserName(e.target.value)} />
        </div>
      </div>
    </div>
  );
};

export default Filters;
