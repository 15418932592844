import { Drawer,  Space, Spin } from "antd";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import RichEditor from "components/rich-text-Editor/RichEditor";
import { useFormik } from "formik";
import { useEffect ,useState} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/country/Actions";
import * as Yup from "yup";
import { SaveConfirmationDialog } from "components/save-confirmation-dialog/SaveConfirmationDialog";

function SpecialityGlobalDrawer(props) {
  const { view, setView, globalDrawer, setGlobalDrawer,editGlobal,setEditGlobal} =
    props;
  const globalRecord = useSelector((state) => state.country.globalRecord);
  const record = editGlobal || view;
  const { Segment, language } = LanguageSegment();
  const [open, setOpen] = useState(false);
 
  const { loading } = useSelector(
    (state) => ({
      loading: state.country?.loading,
    }),
    shallowEqual
  );
  const {
    overview_en = globalRecord.count>0?globalRecord?.records?.[0].overview_en:"",
    overview_ar = globalRecord.count>0?globalRecord?.records?.[0].overview_ar:"",
    overview_de = globalRecord.count>0?globalRecord?.records?.[0].overview_de:"",
    overview_ru = globalRecord.count>0?globalRecord?.records?.[0].overview_ru:"",
    overview_uk = globalRecord.count>0?globalRecord?.records?.[0].overview_uk:"",

    id = globalRecord.count>0?globalRecord?.records?.[0].id:null,
  } = record || {};

  const isEditing = Boolean(record);
  const isLoading = editGlobal ? loading.update : loading.create;
  const title = `${ editGlobal ? " Edit " : " Add "} Global Content`;
  const dispatch = useDispatch();
  const initialValues = {
    overview_en: overview_en || "",
    overview_ar: overview_ar || "",
    overview_ru: overview_ru || "",
    overview_de: overview_de || "",
    overview_uk: overview_uk || "",

    type : "speciality",
  };

  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
  };

  const resetForm = () => {
    formik.resetForm();
  };

  const closeDrawer = () => setGlobalDrawer(false);
  const onDrawerChange = (e) => {
    if (!e) {
      setView(null);
      setEditGlobal(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  useEffect(() => {
    globalDrawer && isEditing && setEditDefaultValues();
    setEditDefaultValues()
  },
  // eslint-disable-next-line
  [globalDrawer]);

  const validationSchema = Yup.object().shape({
  });

  const onSubmit = async (values) => {
    setOpen(false)
    dispatch(actions.loading(editGlobal ? { update: true } : { create: true }));
    if (editGlobal) {
      values.id = id; 
    }
    const action =actions.createGlobal( values, setGlobalDrawer, resetForm)
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"70%"}
        onClose={closeDrawer}
        open={globalDrawer}
        extra={
          <Space>
            {!view && (
              <>
                {!editGlobal && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear 
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={async () => {
                    const errors = await formik.validateForm(); // Trigger validation
                    if (Object.keys(errors).length === 0) { // Check if there are no validation errors
                      setOpen(true); // Open confirmation dialog if form is valid
                    } else {
                      formik.setTouched(
                        Object.keys(formik.values).reduce((acc, field) => ({ ...acc, [field]: true }), {})
                      ); // Mark all fields as touched
                    }
                  }}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div
          className="language-segmented   px-3  mb-2 "
          style={{
            padding: "20px 0",
            margin: 0,
            background: "#fff",
            border: "1px solid #dbdbdb",
            boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.14)",
            borderRadius: "10px",
          }}
        >
          {Segment}
        </div>
        <div className="drawer_main_div ">
          <h2 className=" text-center ">{title}</h2>
          <div className="container-fluid">
            <div className="row">
              <form onSubmit={formik.handleSubmit}>
                {/* /********** Over View ********/}
                {language === "en" && (
                  <>
                    {" "}
                    <div className="my-3">
                      <span className="drawer_span">
                        Overview
                        <span className="form-label-secondary"> (English)</span>
                      </span>
                      <RichEditor
                        disabled={view}
                        value={formik.values?.overview_en || ""}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("overview_en", value)
                        }
                      />
                    </div>
                  </>
                )}
                {language === "ar" && (
                  <>
                    {" "}
                    <div className="my-3">
                      <span className="drawer_span">
                        Overview
                        <span className="form-label-secondary"> (Arabic)</span>
                      </span>
                      <RichEditor
                        disabled={view}
                        value={formik.values.overview_ar}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("overview_ar", value)
                        }
                      />
                    </div>
                  </>
                )}
                {language === "ru" && (
                  <>
                    {" "}
                    <div className="my-3">
                      <span className="drawer_span">
                        Overview
                        <span className="form-label-secondary"> (Russian)</span>
                      </span>
                      <RichEditor
                        disabled={view}
                        value={formik.values.overview_ru}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("overview_ru", value)
                        }
                      />
                    </div>
                  </>
                )}
                {language === "de" && (
                  <>
                    {" "}
                    <div className="my-3">
                      <span className="drawer_span">
                        Overview
                        <span className="form-label-secondary"> (German)</span>
                      </span>
                      <RichEditor
                        disabled={view}
                        value={formik.values.overview_de}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("overview_de", value)
                        }
                      />
                    </div>
                  </>
                )}
                 {language === "uk" && (
                  <>
                    {" "}
                    <div className="my-3">
                      <span className="drawer_span">
                        Overview
                        <span className="form-label-secondary"> (Ukrainian)</span>
                      </span>
                      <RichEditor
                        disabled={view}
                        value={formik.values.overview_uk}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("overview_uk", value)
                        }
                      />
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </Drawer>
      <SaveConfirmationDialog
        loading={loading?.remove}
        open={open}
        onClose={setOpen}
        onSave={formik.handleSubmit}
        title="Save"
        content="Are you sure you want to Save this?"
        onCancelLabel="Cancel"
        onConfirmLabel="Save"
      />
    </>
  );
}

export default SpecialityGlobalDrawer;
    