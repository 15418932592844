import { Drawer, Space, Spin } from "antd";
import { useFormik } from "formik";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/request/Actions";
import * as Yup from "yup";

const RequestCommentModal = ({ commentModal, ...props }) => {
  const { setCommentModal, editRecord, setEditRecord,editComment,setEditComment } = props;
  const closeDrawer = () => setCommentModal(false)

  const user = useSelector((state) => state.auth.user);
  const { loading } = useSelector(
    (state) => ({
      loading: state.request?.loading    }),
    shallowEqual
  );
  const view = false;
  const isEditing = Boolean(editComment);
  const isLoading = isEditing ? loading.update : loading.create;

  const title = isEditing? "Edit Comment":"Add Comment";
  const dispatch = useDispatch();

  //formik Handler
  const initialValues = {
    text: isEditing ? editComment?.text : null,  
    userId: user?.id || null,
    requestId: (isEditing && editComment && editComment.requestId ?  editComment.requestId : editComment?.request?.id)  || commentModal || null
  };

  const validationSchema = Yup.object().shape({
    text: Yup.string().required("Comment is Required"),
    userId: Yup.string(),
    requestId: Yup.string(),
    });

  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
  };

  const onDrawerChange = (e) => {
    if (!e) {
      editComment && setEditComment(null);
      editRecord && setEditRecord(null);
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  const onSubmit = async (values) => {
    dispatch(actions.loading({ update: true }));    
    const action = isEditing
    
    ? actions.updateComment(editComment?.id, values, setCommentModal)
    : actions.comment({ ...values }, setCommentModal,true);
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  useEffect(() => {
    if (commentModal) {
      if (isEditing && editComment) {
        setEditDefaultValues();  // Set form values when editing
      } else {
        formik.resetForm({ values: initialValues }); // Reset form values when adding a new comment
      }
    }
  },
  // eslint-disable-next-line
  [commentModal, isEditing, editComment]);
  
  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"70%"}
        onClose={closeDrawer}
        open={Boolean(commentModal)}
        extra={
          <Space>
            {!view && (
              <>
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <>
          <div className="drawer_main_div">
            <h2 className=" text-center">{title}</h2>
            <div className="container-fluid">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="my-2">
                    <span className="drawer_span required">Comment</span>
                    <textarea
                      placeholder="Comment..."
                      name="text"
                      rows="7"
                      className={`form-control w-100 drawer_input ${
                        formik.errors.text && formik.touched.text
                          ? "input_error"
                          : ""
                      }`}
                      value={formik.values.text || ""} 
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default RequestCommentModal;
