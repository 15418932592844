import { notification } from "antd";
import { CRUD_COMMON_MESSAGE } from "utils/constants/contants";
import * as requestFromServer from "./Crud";
import { contentSlice } from "./Reducer";
const { actions } = contentSlice;

export const loading = (payload) => (dispatch) => {
  dispatch(actions.setLoading(payload));
};

export const getAll = (pagination) => (dispatch) => {
  dispatch(actions.setLoading({ getAll: true }));
  return requestFromServer
    .getAll(pagination)
    .then((response) => {
      const { records = [], count = 0 } = response.data;
      dispatch(actions.getAll({ records, count }));
    })
    .catch(() => {})
    .finally(() => dispatch(actions.setLoading({ getAll: false })));
};
export const getEditContent = (pagination) => (dispatch) => {
  dispatch(actions.setLoading({ getAll: true }));
  return requestFromServer
    .getEditContent(pagination)
    .then((response) => {
      const { records = [], count = 0 } = response.data;
      if (records.length > 0) {
        return records[0];
      } else {
        return []; 
      }
    })
    .catch(() => {})
    .finally(() => dispatch(actions.setLoading({ getAll: false })));
};
export const getAllDraft = (pagination) => (dispatch) => {
  dispatch(actions.setLoading({ getAllDraft: true }));
  return requestFromServer
    .getAllDraft(pagination)
    .then((response) => {
      const { records = [], count = 0 } = response.data;
      dispatch(actions.getAllDraft({ records, count }));
    })
    .catch(() => {})
    .finally(() => dispatch(actions.setLoading({ getAllDraft: false })));
};
export const create =
  (data, setDrawer, resetForm, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ create: true }));
    return requestFromServer
      .create(data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
          dispatch(getAll(pagination));
          setDrawer(false);
          resetForm();
        }
      })
      .catch((error) => {})
      .finally(() => dispatch(actions.setLoading({ create: false })));
  };


export const remove = (id, setDeleteRecord, pagination) => (dispatch) => {
  if(!id){
    setDeleteRecord(null);
      notification.warning({ description: CRUD_COMMON_MESSAGE.CONTENT_NOT_AVAILABLE });
  }
  else{
    dispatch(actions.setLoading({ remove: true }));
    return requestFromServer
      .remove(id)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.REMOVE });
          dispatch(getAll(pagination));
          setDeleteRecord(null);
        }
      })
      .catch(() => {})
      .finally(() => dispatch(actions.setLoading({ remove: false })));
  }
  
};

export const removeFilter = (id, setDeleteRecord, pagination,setDeletedFlag) => (dispatch) => {
  if(!id){
    setDeleteRecord(null);
      notification.warning({ description: CRUD_COMMON_MESSAGE.CONTENT_NOT_AVAILABLE });
  }
  else{
    dispatch(actions.setLoading({ remove: true }));
    return requestFromServer
      .remove(id)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.REMOVE });
          // dispatch(contentFilters(pagination));
          setDeleteRecord(null);
          setDeletedFlag(true);
        }
      })
      .catch(() => {})
      .finally(() => dispatch(actions.setLoading({ remove: false })));
  }
  
};
export const update =
  (id, data, setDrawer, resetForm, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ update: true }));
    return requestFromServer
      .update(id, data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.UPDATE });
          dispatch(getAll(pagination));
          dispatch(getAllDraft(pagination));
          setDrawer(false);
          resetForm();
        }
      })
      .catch(() => {})
      .finally(() => dispatch(actions.setLoading({ update: false })));
  };


  export const updateDraftStatus =
  (id, data, setRejectRecord, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ update: true }));
    return requestFromServer
      .updateDraft(id, data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.UPDATE });
          dispatch(getAllDraft(pagination));
          setRejectRecord(false);
        }
      })
      .catch(() => {})
      .finally(() => dispatch(actions.setLoading({ update: false })));
  };


  export const contentFilters = (pagination) => (dispatch) => {
    dispatch(actions.setLoading({ getAll: true }));
    return requestFromServer
      .contentFilters(pagination)
      .then((response) => {
        return response;
      })
      .catch(() => {})
      .finally(() => dispatch(actions.setLoading({ getAll: false })));
  };