import React,{ useState } from "react";
import { createQueryParams } from "utils/helpers/helper-functions";
import TeamsDrawer from "./teams.drawer";
import TeamsTable from "./teams.table";
import { teamRelations as relations } from "services/relations";

function Teams() {
  const [drawer, setDrawer] = useState(false);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [remove, setRemove] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");

  const getPagination = () => {
    const limit = pageSize;
    let offset = (pageNumber - 1) * pageSize;
    offset = offset > 1 ? offset : 0;
    const text = searchText.trim() || null;
    const where = "";
    const includeOverview="";
    
    const query = createQueryParams(limit, offset, text, relations,where,includeOverview);
    return { limit, offset, text, relations, query };
  };

  const commonProps = {
    remove,
    setRemove,
    edit,
    setEdit,
    view,
    setView,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    drawer,
    setDrawer,
  };

  return (
    <div>
      <div className="py-4 mt-3 table_main_div d-flex justify-content-between">
        <h1 className="mx-5 mt-2">Team</h1>
        <div className="buttons-on-title  mx-5">
          <button onClick={() => setDrawer(true)} className="btn btn-theme">  Add Team Player </button>
         
        </div>
      </div>
      <TeamsTable {...commonProps} />
      <TeamsDrawer {...commonProps} />
    </div>
  );
}

export default Teams;
