import { Navigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { UserRole } from "utils/common/common";

//  used to validate if user is currently logged in or  not as Editor
export const AdminEditorAuthorValidationRoute = ({ children }) => {
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  );

  if (!authState.firebaseToken || !authState.user) {
    return <Navigate to="/login" />;
  }
  if (authState?.user?.role !== UserRole.Editor && authState?.user?.role !== UserRole.Author && authState?.user?.role !== UserRole.Admin) {
    return <Navigate to="/profile" />;
  }
  return children;
};
