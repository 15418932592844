export const DefaultLanguages = [
  {
    label: <span className="required">English </span>,
    value: "en",
  },
  { label: "Arabic", value: "ar" },
  { label: "German", value: "de" },
  { label: "Russian", value: "ru" },
  { label: "Ukrainian", value: "uk" },

];

export const UserRole = {
  Admin: "admin",
  Doctor: "doctor",
  Author:"author",
  Editor:"editor",
  CustomerSupport: "customer-support",
};


export const COUNTRIES_TRANSLATED_LIST = [
  {
    code: "AF",
    name_en: "Afghanistan",
    name_de: "Afghanistan",
    name_ar: "أفغانستان",
    name_ru: "Афганистан",
    name_uk: "Афганістан"
  },
  {
    code: "AX",
    name_en: "Åland Islands",
    name_de: "Ålandinseln",
    name_ar: "جزر أولاند",
    name_ru: "Аландские острова",
    name_uk: "Аландські острови"
  },
  {
    code: "AL",
    name_en: "Albania",
    name_de: "Albanien",
    name_ar: "ألبانيا",
    name_ru: "Албания",
    name_uk: "Албанія"
  },
  {
    code: "DZ",
    name_en: "Algeria",
    name_de: "Algerien",
    name_ar: "الجزائر",
    name_ru: "Алжир",
    name_uk: "Алжир"
  },
  {
    code: "AS",
    name_en: "American Samoa",
    name_de: "Amerikanisch-Samoa",
    name_ar: "ساموا الأمريكية",
    name_ru: "Американское Самоа",
    name_uk: "Американське Самоа"
  },
  {
    code: "AD",
    name_en: "Andorra",
    name_de: "Andorra",
    name_ar: "أندورا",
    name_ru: "Андорра",
    name_uk: "Андорра"
  },
  {
    code: "AO",
    name_en: "Angola",
    name_de: "Angola",
    name_ar: "أنغولا",
    name_ru: "Ангола",
    name_uk: "Ангола"
  },
  {
    code: "AI",
    name_en: "Anguilla",
    name_de: "Anguilla",
    name_ar: "أنغيلا",
    name_ru: "Ангилья",
    name_uk: "Ангілья"
  },
  {
    code: "AQ",
    name_en: "Antarctica",
    name_de: "Antarktis",
    name_ar: "أنتاركتيكا",
    name_ru: "Антарктика",
    name_uk: "Антарктида"
  },
  {
    code: "AG",
    name_en: "Antigua and Barbuda",
    name_de: "Antigua und Barbuda",
    name_ar: "أنتيغوا وبربودا",
    name_ru: "Антигуа и Барбуда",
    name_uk: "Антигуа і Барбуда"
  },
  {
    code: "AR",
    name_en: "Argentina",
    name_de: "Argentinien",
    name_ar: "الأرجنتين",
    name_ru: "Аргентина",
    name_uk: "Аргентина"
  },
  {
    code: "AM",
    name_en: "Armenia",
    name_de: "Armenien",
    name_ar: "أرمينيا",
    name_ru: "Армения",
    name_uk: "Вірменія"
  },
  {
    code: "AW",
    name_en: "Aruba",
    name_de: "Aruba",
    name_ar: "أروبا",
    name_ru: "Аруба",
    name_uk: "Аруба"
  },
  {
    code: "AU",
    name_en: "Australia",
    name_de: "Australien",
    name_ar: "أستراليا",
    name_ru: "Австралия",
    name_uk: "Австралія"
  },
  {
    code: "AT",
    name_en: "Austria",
    name_de: "Österreich",
    name_ar: "النمسا",
    name_ru: "Австрия",
    name_uk: "Австрія"
  },
  {
    code: "AZ",
    name_en: "Azerbaijan",
    name_de: "Aserbaidschan",
    name_ar: "أذربيجان",
    name_ru: "Азербайджан",
    name_uk: "Азербайджан"
  },
  {
    code: "BS",
    name_en: "Bahamas",
    name_de: "Bahamas",
    name_ar: "الباهاماس",
    name_ru: "Багамы",
    name_uk: "Багамські острови"
  },
  {
    code: "BH",
    name_en: "Bahrain",
    name_de: "Bahrain",
    name_ar: "البحرين",
    name_ru: "Бахрейн",
    name_uk: "Бахрейн"
  },
  {
    code: "BD",
    name_en: "Bangladesh",
    name_de: "Bangladesch",
    name_ar: "بنغلاديش",
    name_ru: "Бангладеш",
    name_uk: "Бангладеш"
  },
  {
    code: "BB",
    name_en: "Barbados",
    name_de: "Barbados",
    name_ar: "باربادوس",
    name_ru: "Барбадос",
    name_uk: "Барбадос"
  },
  {
    code: "BY",
    name_en: "Belarus",
    name_de: "Weißrussland",
    name_ar: "بيلاروسيا",
    name_ru: "Беларусь",
    name_uk: "Білорусь"
  },
  {
    code: "BE",
    name_en: "Belgium",
    name_de: "Belgien",
    name_ar: "بلجيكا",
    name_ru: "Бельгия",
    name_uk: "Бельгія"
  },
  {
    code: "BZ",
    name_en: "Belize",
    name_de: "Belize",
    name_ar: "بليز",
    name_ru: "Белиз",
    name_uk: "Беліз"
  },
  {
    code: "BJ",
    name_en: "Benin",
    name_de: "Benin",
    name_ar: "بنين",
    name_ru: "Бенин",
    name_uk: "Бенін"
  },
  {
    code: "BM",
    name_en: "Bermuda",
    name_de: "Bermuda",
    name_ar: "برمودا",
    name_ru: "Бермуды",
    name_uk: "Бермуди"
  },
  {
    code: "BT",
    name_en: "Bhutan",
    name_de: "Bhutan",
    name_ar: "بوتان",
    name_ru: "Бутан",
    name_uk: "Бутан"
  },
  {
    code: "BO",
    name_en: "Bolivia",
    name_de: "Bolivien",
    name_ar: "بوليفيا",
    name_ru: "Боливия",
    name_uk: "Болівія"
  },
  {
    code: "BA",
    name_en: "Bosnia and Herzegovina",
    name_de: "Bosnien und Herzegowina",
    name_ar: "البوسنة والهرسك",
    name_ru: "Босния и Герцеговина",
    name_uk: "Боснія та Герцеговина"
  },
  {
    code: "BW",
    name_en: "Botswana",
    name_de: "Botswana",
    name_ar: "بوتسوانا",
    name_ru: "Ботсвана",
    name_uk: "Ботсвана"
  },
  {
    code: "BV",
    name_en: "Bouvet Island",
    name_de: "Bouvetinsel",
    name_ar: "جزيرة بوفet",
    name_ru: "Остров Буве",
    name_uk: "Острів Буве"
  },
  {
    code: "BR",
    name_en: "Brazil",
    name_de: "Brasilien",
    name_ar: "البرازيل",
    name_ru: "Бразилия",
    name_uk: "Бразилія"
  },
  {
    code: "IO",
    name_en: "British Indian Ocean Territory",
    name_de: "Britisches Territorium im Indischen Ozean",
    name_ar: "إقليم المحيط الهندي البريطاني",
    name_ru: "Британская территория в Индийском океане",
    name_uk: "Британська територія в Індійському океані"
  },
  {
    code: "BN",
    name_en: "Brunei Darussalam",
    name_de: "Brunei Darussalam",
    name_ar: "بروناي دار السلام",
    name_ru: "Бруней-Даруссалам",
    name_uk: "Бруней"
  },
  {
    code: "BG",
    name_en: "Bulgaria",
    name_de: "Bulgarien",
    name_ar: "بلغاريا",
    name_ru: "Болгария",
    name_uk: "Болгарія"
  },
  {
    code: "BF",
    name_en: "Burkina Faso",
    name_de: "Burkina Faso",
    name_ar: "بوركينا فاسو",
    name_ru: "Буркина-Фасо",
    name_uk: "Буркіна-Фасо"
  },
  {
    code: "BI",
    name_en: "Burundi",
    name_de: "Burundi",
    name_ar: "بوروندي",
    name_ru: "Бурунди",
    name_uk: "Бурунді"
  },
  {
    code: "KH",
    name_en: "Cambodia",
    name_de: "Kambodscha",
    name_ar: "كمبوديا",
    name_ru: "Камбоджа",
    name_uk: "Камбоджа"
  },
  {
    code: "CM",
    name_en: "Cameroon",
    name_de: "Kamerun",
    name_ar: "الكاميرون",
    name_ru: "Камерун",
    name_uk: "Камерун"
  },
  {
    code: "CA",
    name_en: "Canada",
    name_de: "Kanada",
    name_ar: "كندا",
    name_ru: "Канада",
    name_uk: "Канада"
  },
  {
    code: "CV",
    name_en: "Cape Verde",
    name_de: "Kap Verde",
    name_ar: "الرأس الأخضر",
    name_ru: "Кабо-Верде",
    name_uk: "Кабо-Верде"
  },
  {
    code: "KY",
    name_en: "Cayman Islands",
    name_de: "Kaimaninseln",
    name_ar: "جزر كايمان",
    name_ru: "Каймановы острова",
    name_uk: "Кайманові острови"
  },
  {
    code: "CF",
    name_en: "Central African Republic",
    name_de: "Zentralafrikanische Republik",
    name_ar: "جمهورية أفريقيا الوسطى",
    name_ru: "Центральноафриканская Республика",
    name_uk: "Центральноафриканська Республіка"
  },
  {
    code: "TD",
    name_en: "Chad",
    name_de: "Tschad",
    name_ar: "تشاد",
    name_ru: "Чад",
    name_uk: "Чад"
  },
  {
    code: "CL",
    name_en: "Chile",
    name_de: "Chile",
    name_ar: "تشيلي",
    name_ru: "Чили",
    name_uk: "Чилі"
  },
  {
    code: "CN",
    name_en: "China",
    name_de: "China",
    name_ar: "الصين",
    name_ru: "Китай",
    name_uk: "Китай"
  },
  {
    code: "CX",
    name_en: "Christmas Island",
    name_de: "Weihnachtsinsel",
    name_ar: "جزيرة الكريسماس",
    name_ru: "Остров Рождества",
    name_uk: "Острів Різдва"
  },
  {
    code: "CC",
    name_en: "Cocos (Keeling) Islands",
    name_de: "Kokosinseln (Keeling)",
    name_ar: "جزر كوكوس (كيلينغ)",
    name_ru: "Кокосовые острова (Килинг)",
    name_uk: "Кокосові (Кілінг) острови"
  },
  {
    code: "CO",
    name_en: "Colombia",
    name_de: "Kolumbien",
    name_ar: "كولومبيا",
    name_ru: "Колумбия",
    name_uk: "Колумбія"
  },
  {
    code: "KM",
    name_en: "Comoros",
    name_de: "Komoren",
    name_ar: "جزر القمر",
    name_ru: "Коморы",
    name_uk: "Комори"
  },
  {
    code: "CG",
    name_en: "Congo",
    name_de: "Kongo",
    name_ar: "الكونغو",
    name_ru: "Конго",
    name_uk: "Конго"
  },
  {
    code: "CD",
    name_en: "Congo, The Democratic Republic of the",
    name_de: "Demokratische Republik Kongo",
    name_ar: "جمهورية الكونغو الديمقراطية",
    name_ru: "Конго, Демократическая Республика",
    name_uk: "Конго, Демократична Республіка"
  },
  {
    code: "CK",
    name_en: "Cook Islands",
    name_de: "Cookinseln",
    name_ar: "جزر كوك",
    name_ru: "Острова Кука",
    name_uk: "Острови Кука"
  },
  {
    code: "CR",
    name_en: "Costa Rica",
    name_de: "Costa Rica",
    name_ar: "كوستاريكا",
    name_ru: "Коста-Рика",
    name_uk: "Коста-Рика"
  },
  {
    code: "CI",
    name_en: "Cote D'Ivoire",
    name_de: "Elfenbeinküste",
    name_ar: "ساحل العاج",
    name_ru: "Кот-д'Ивуар",
    name_uk: "Кот-д'Івуар"
  },
  {
    code: "HR",
    name_en: "Croatia",
    name_de: "Kroatien",
    name_ar: "كرواتيا",
    name_ru: "Хорватия",
    name_uk: "Хорватія"
  },
  {
    code: "CU",
    name_en: "Cuba",
    name_de: "Kuba",
    name_ar: "كوبا",
    name_ru: "Куба",
    name_uk: "Куба"
  },
  {
    code: "CY",
    name_en: "Cyprus",
    name_de: "Zypern",
    name_ar: "قبرص",
    name_ru: "Кипр",
    name_uk: "Кіпр"
  },
  {
    code: "CZ",
    name_en: "Czech Republic",
    name_de: "Tschechische Republik",
    name_ar: "جمهورية التشيك",
    name_ru: "Чешская Республика",
    name_uk: "Чехія"
  },
  {
    code: "DK",
    name_en: "Denmark",
    name_de: "Dänemark",
    name_ar: "الدنمارك",
    name_ru: "Дания",
    name_uk: "Данія"
  },
  {
    code: "DJ",
    name_en: "Djibouti",
    name_de: "Dschibuti",
    name_ar: "جيبوتي",
    name_ru: "Джибути",
    name_uk: "Джибуті"
  },
  {
    code: "DM",
    name_en: "Dominica",
    name_de: "Dominica",
    name_ar: "دومينيكا",
    name_ru: "Доминика",
    name_uk: "Домініка"
  },
  {
    code: "DO",
    name_en: "Dominican Republic",
    name_de: "Dominikanische Republik",
    name_ar: "جمهورية الدومينيكان",
    name_ru: "Доминиканская Республика",
    name_uk: "Домініканська Республіка"
  },
  {
    code: "EC",
    name_en: "Ecuador",
    name_de: "Ecuador",
    name_ar: "الإكوادور",
    name_ru: "Эквадор",
    name_uk: "Еквадор"
  },
  {
    code: "EG",
    name_en: "Egypt",
    name_de: "Ägypten",
    name_ar: "مصر",
    name_ru: "Египет",
    name_uk: "Єгипет"
  },
  {
    code: "SV",
    name_en: "El Salvador",
    name_de: "El Salvador",
    name_ar: "السلفادور",
    name_ru: "Сальвадор",
    name_uk: "Сальвадор"
  },
  {
    code: "GQ",
    name_en: "Equatorial Guinea",
    name_de: "Äquatorialguinea",
    name_ar: "غينيا الاستوائية",
    name_ru: "Экваториальная Гвинея",
    name_uk: "Екваторіальна Гвінея"
  },
  {
    code: "ER",
    name_en: "Eritrea",
    name_de: "Eritrea",
    name_ar: "إريتريا",
    name_ru: "Эритрея",
    name_uk: "Еритрея"
  },
  {
    code: "EE",
    name_en: "Estonia",
    name_de: "Estland",
    name_ar: "إستونيا",
    name_ru: "Эстония",
    name_uk: "Естонія"
  },
  {
    code: "ET",
    name_en: "Ethiopia",
    name_de: "Äthiopien",
    name_ar: "إثيوبيا",
    name_ru: "Эфиопия",
    name_uk: "Ефіопія"
  },
  {
    code: "FK",
    name_en: "Falkland Islands (Malvinas)",
    name_de: "Falklandinseln (Malwinen)",
    name_ar: "جزر فوكلاند (مالفيناس)",
    name_ru: "Фолклендские острова (Мальвины)",
    name_uk: "Фолклендські острови (Мальвінські)"
  },
  {
    code: "FO",
    name_en: "Faroe Islands",
    name_de: "Färöer-Inseln",
    name_ar: "جزر فارو",
    name_ru: "Фарерские острова",
    name_uk: "Фарерські острови"
  },
  {
    code: "FJ",
    name_en: "Fiji",
    name_de: "Fidschi",
    name_ar: "فيجي",
    name_ru: "Фиджи",
    name_uk: "Фіджі"
  },
  {
    code: "FI",
    name_en: "Finland",
    name_de: "Finnland",
    name_ar: "فنلندا",
    name_ru: "Финляндия",
    name_uk: "Фінляндія"
  },
  {
    code: "FR",
    name_en: "France",
    name_de: "Frankreich",
    name_ar: "فرنسا",
    name_ru: "Франция",
    name_uk: "Франція"
  },
  {
    code: "GF",
    name_en: "French Guiana",
    name_de: "Französisch-Guayana",
    name_ar: "غويانا الفرنسية",
    name_ru: "Французская Гвиана",
    name_uk: "Французька Гвіана"
  },
  {
    code: "PF",
    name_en: "French Polynesia",
    name_de: "Französisch-Polynesien",
    name_ar: "بولينيزيا الفرنسية",
    name_ru: "Французская Полинезия",
    name_uk: "Французька Полінезія"
  },
  {
    code: "TF",
    name_en: "French Southern Territories",
    name_de: "Französische Süd- und Antarktisgebiete",
    name_ar: "المناطق الجنوبية الفرنسية",
    name_ru: "Французские Южные Территории",
    name_uk: "Французькі Південні Території"
  },
  {
    code: "GA",
    name_en: "Gabon",
    name_de: "Gabun",
    name_ar: "الغابون",
    name_ru: "Габон",
    name_uk: "Габон"
  },
  {
    code: "GM",
    name_en: "Gambia",
    name_de: "Gambia",
    name_ar: "غامبيا",
    name_ru: "Гамбия",
    name_uk: "Гамбія"
  },
  {
    code: "GE",
    name_en: "Georgia",
    name_de: "Georgien",
    name_ar: "جورجيا",
    name_ru: "Грузия",
    name_uk: "Грузія"
  },
  {
    code: "DE",
    name_en: "Germany",
    name_de: "Deutschland",
    name_ar: "ألمانيا",
    name_ru: "Германия",
    name_uk: "Німеччина"
  },
  {
    code: "GH",
    name_en: "Ghana",
    name_de: "Ghana",
    name_ar: "غانا",
    name_ru: "Гана",
    name_uk: "Гана"
  },
  {
    code: "GI",
    name_en: "Gibraltar",
    name_de: "Gibraltar",
    name_ar: "جبل طارق",
    name_ru: "Гибралтар",
    name_uk: "Гібралтар"
  },
  {
    code: "GR",
    name_en: "Greece",
    name_de: "Griechenland",
    name_ar: "اليونان",
    name_ru: "Греция",
    name_uk: "Греція"
  },
  {
    code: "GL",
    name_en: "Greenland",
    name_de: "Grönland",
    name_ar: "غرينلاند",
    name_ru: "Гренландия",
    name_uk: "Гренландія"
  },
  {
    code: "GD",
    name_en: "Grenada",
    name_de: "Grenada",
    name_ar: "غرينادا",
    name_ru: "Гренада",
    name_uk: "Гренада"
  },
  {
    code: "GP",
    name_en: "Guadeloupe",
    name_de: "Guadeloupe",
    name_ar: "غوادلوب",
    name_ru: "Гваделупа",
    name_uk: "Гваделупа"
  },
  {
    code: "GU",
    name_en: "Guam",
    name_de: "Guam",
    name_ar: "غوام",
    name_ru: "Гуам",
    name_uk: "Гуам"
  },
  {
    code: "GT",
    name_en: "Guatemala",
    name_de: "Guatemala",
    name_ar: "غواتيمالا",
    name_ru: "Гватемала",
    name_uk: "Гватемала"
  },
  {
    code: "GG",
    name_en: "Guernsey",
    name_de: "Guernsey",
    name_ar: "غيرنزي",
    name_ru: "Гернси",
    name_uk: "Гернсі"
  },
  {
    code: "GN",
    name_en: "Guinea",
    name_de: "Guinea",
    name_ar: "غينيا",
    name_ru: "Гвинея",
    name_uk: "Гвінея"
  },
  {
    code: "GW",
    name_en: "Guinea-Bissau",
    name_de: "Guinea-Bissau",
    name_ar: "غينيا بيساو",
    name_ru: "Гвинея-Бисау",
    name_uk: "Гвінея-Бісау"
  },
  {
    code: "GY",
    name_en: "Guyana",
    name_de: "Guyana",
    name_ar: "غويانا",
    name_ru: "Гайана",
    name_uk: "Гаяна"
  },
  {
    code: "HT",
    name_en: "Haiti",
    name_de: "Haiti",
    name_ar: "هايتي",
    name_ru: "Гаити",
    name_uk: "Гаїті"
  },
  {
    code: "HM",
    name_en: "Heard Island and McDonald Islands",
    name_de: "Heard-Inseln und McDonald-Inseln",
    name_ar: "جزيرة هيرد وجزر ماكدونالد",
    name_ru: "Острова Херд и Макдональд",
    name_uk: "Острови Херд і Макдональд"
  },
  {
    code: "VA",
    name_en: "Holy See (Vatican City State)",
    name_de: "Heiliger Stuhl (Vatikanstadt)",
    name_ar: "الكرسي المقدس (دولة الفاتيكان)",
    name_ru: "Святой Престол (Государство Ватикан)",
    name_uk: "Святий Престол (Держава Ватикан)"
  },
  {
    code: "HN",
    name_en: "Honduras",
    name_de: "Honduras",
    name_ar: "هندوراس",
    name_ru: "Гондурас",
    name_uk: "Гондурас"
  },
  {
    code: "HK",
    name_en: "Hong Kong",
    name_de: "Hongkong",
    name_ar: "هونغ كونغ",
    name_ru: "Гонконг",
    name_uk: "Гонконг"
  },
  {
    code: "HU",
    name_en: "Hungary",
    name_de: "Ungarn",
    name_ar: "هنغاريا",
    name_ru: "Венгрия",
    name_uk: "Угорщина"
  },
  {
    code: "IS",
    name_en: "Iceland",
    name_de: "Island",
    name_ar: "أيسلندا",
    name_ru: "Исландия",
    name_uk: "Ісландія"
  },
  {
    code: "IN",
    name_en: "India",
    name_de: "Indien",
    name_ar: "الهند",
    name_ru: "Индия",
    name_uk: "Індія"
  },
  {
    code: "ID",
    name_en: "Indonesia",
    name_de: "Indonesien",
    name_ar: "إندونيسيا",
    name_ru: "Индонезия",
    name_uk: "Індонезія"
  },
  {
    code: "IR",
    name_en: "Iran, Islamic Republic Of",
    name_de: "Iran, Islamische Republik",
    name_ar: "إيران",
    name_ru: "Иран, Исламская Республика",
    name_uk: "Іран, Ісламська Республіка"
  },
  {
    code: "IQ",
    name_en: "Iraq",
    name_de: "Irak",
    name_ar: "العراق",
    name_ru: "Ирак",
    name_uk: "Ірак"
  },
  {
    code: "IE",
    name_en: "Ireland",
    name_de: "Irland",
    name_ar: "أيرلندا",
    name_ru: "Ирландия",
    name_uk: "Ірландія"
  },
  {
    code: "IM",
    name_en: "Isle of Man",
    name_de: "Isle of Man",
    name_ar: "جزيرة مان",
    name_ru: "Остров Мэн",
    name_uk: "Острів Мен"
  },
  {
    code: "IL",
    name_en: "Israel",
    name_de: "Israel",
    name_ar: "إسرائيل",
    name_ru: "Израиль",
    name_uk: "Ізраїль"
  },
  {
    code: "IT",
    name_en: "Italy",
    name_de: "Italien",
    name_ar: "إيطاليا",
    name_ru: "Италия",
    name_uk: "Італія"
  },
  {
    code: "JM",
    name_en: "Jamaica",
    name_de: "Jamaika",
    name_ar: "جامايكا",
    name_ru: "Ямайка",
    name_uk: "Ямайка"
  },
  {
    code: "JP",
    name_en: "Japan",
    name_de: "Japan",
    name_ar: "اليابان",
    name_ru: "Япония",
    name_uk: "Японія"
  },
  {
    code: "JE",
    name_en: "Jersey",
    name_de: "Jersey",
    name_ar: "جيرسي",
    name_ru: "Джерси",
    name_uk: "Джерсі"
  },
  {
    code: "JO",
    name_en: "Jordan",
    name_de: "Jordanien",
    name_ar: "الأردن",
    name_ru: "Иордания",
    name_uk: "Йорданія"
  },
  {
    code: "KZ",
    name_en: "Kazakhstan",
    name_de: "Kasachstan",
    name_ar: "كازاخستان",
    name_ru: "Казахстан",
    name_uk: "Казахстан"
  },
  {
    code: "KE",
    name_en: "Kenya",
    name_de: "Kenia",
    name_ar: "كينيا",
    name_ru: "Кения",
    name_uk: "Кенія"
  },
  {
    code: "KI",
    name_en: "Kiribati",
    name_de: "Kiribati",
    name_ar: "كيريباتي",
    name_ru: "Кирибати",
    name_uk: "Кірібаті"
  },
  {
    code: "KP",
    name_en: "Korea, Democratic People'S Republic of",
    name_de: "Korea, Demokratische Volksrepublik",
    name_ar: "كوريا، جمهورية كوريا الديمقراطية الشعبية",
    name_ru: "Корея, Демократическая Народная Республика",
    name_uk: "Корея, Корейська Народно-Демократична Республіка"
  },
  {
    code: "KR",
    name_en: "Korea, Republic of",
    name_de: "Korea, Republik",
    name_ar: "كوريا، جمهورية كوريا",
    name_ru: "Корея, Республика",
    name_uk: "Корея, Республіка Корея"
  },
  {
    code: "KW",
    name_en: "Kuwait",
    name_de: "Kuwait",
    name_ar: "الكويت",
    name_ru: "Кувейт",
    name_uk: "Кувейт"
  },
  {
    code: "KG",
    name_en: "Kyrgyzstan",
    name_de: "Kirgistan",
    name_ar: "قرغيزستان",
    name_ru: "Киргизия",
    name_uk: "Киргизстан"
  },
  {
    code: "LA",
    name_en: "Lao People'S Democratic Republic",
    name_de: "Laos, Demokratische Volksrepublik",
    name_ar: "لاوس، جمهورية لاو الديمقراطية الشعبية",
    name_ru: "Лаос, Лаосская Народно-Демократическая Республика",
    name_uk: "Лаос, Лаоська Народно-Демократична Республіка"
  },
  {
    code: "LV",
    name_en: "Latvia",
    name_de: "Lettland",
    name_ar: "لاتفيا",
    name_ru: "Латвия",
    name_uk: "Латвія"
  },
  {
    code: "LB",
    name_en: "Lebanon",
    name_de: "Libanon",
    name_ar: "لبنان",
    name_ru: "Ливан",
    name_uk: "Ліван"
  },
  {
    code: "LS",
    name_en: "Lesotho",
    name_de: "Lesotho",
    name_ar: "ليسوتو",
    name_ru: "Лесото",
    name_uk: "Лесото"
  },
  {
    code: "LR",
    name_en: "Liberia",
    name_de: "Liberia",
    name_ar: "ليبيريا",
    name_ru: "Либерия",
    name_uk: "Ліберія"
  },
  {
    code: "LY",
    name_en: "Libyan Arab Jamahiriya",
    name_de: "Libysch-Arabische Dschamahirija",
    name_ar: "الجماهيرية العربية الليبية",
    name_ru: "Ливийская Арабская Джамахирия",
    name_uk: "Лівійська Арабська Джамахірія"
  },
  {
    code: "LI",
    name_en: "Liechtenstein",
    name_de: "Liechtenstein",
    name_ar: "ليختنشتاين",
    name_ru: "Лихтенштейн",
    name_uk: "Ліхтенштейн"
  },
  {
    code: "LT",
    name_en: "Lithuania",
    name_de: "Litauen",
    name_ar: "ليتوانيا",
    name_ru: "Литва",
    name_uk: "Литва"
  },
  {
    code: "LU",
    name_en: "Luxembourg",
    name_de: "Luxemburg",
    name_ar: "لوكسمبورغ",
    name_ru: "Люксембург",
    name_uk: "Люксембург"
  },
  {
    code: "MO",
    name_en: "Macao",
    name_de: "Macau",
    name_ar: "ماكاو",
    name_ru: "Макао",
    name_uk: "Макао"
  },
  {
    code: "MK",
    name_en: "Macedonia, The Former Yugoslav Republic of",
    name_de: "Mazedonien, Ehemalige jugoslawische Republik",
    name_ar: "مقدونيا، جمهورية يوغوسلافيا السابقة",
    name_ru: "Македония, Бывшая Югославская Республика",
    name_uk: "Північна Македонія"
  },
  {
    code: "MG",
    name_en: "Madagascar",
    name_de: "Madagaskar",
    name_ar: "مدغشقر",
    name_ru: "Мадагаскар",
    name_uk: "Мадагаскар"
  },
  {
    code: "MW",
    name_en: "Malawi",
    name_de: "Malawi",
    name_ar: "مالاوي",
    name_ru: "Малави",
    name_uk: "Малаві"
  },
  {
    code: "MY",
    name_en: "Malaysia",
    name_de: "Malaysia",
    name_ar: "ماليزيا",
    name_ru: "Малайзия",
    name_uk: "Малайзія"
  },
  {
    code: "MV",
    name_en: "Maldives",
    name_de: "Malediven",
    name_ar: "جزر المالديف",
    name_ru: "Мальдивы",
    name_uk: "Мальдіви"
  },
  {
    code: "ML",
    name_en: "Mali",
    name_de: "Mali",
    name_ar: "مالي",
    name_ru: "Мали",
    name_uk: "Малі"
  },
  {
    code: "MT",
    name_en: "Malta",
    name_de: "Malta",
    name_ar: "مالطا",
    name_ru: "Мальта",
    name_uk: "Мальта"
  },
  {
    code: "MH",
    name_en: "Marshall Islands",
    name_de: "Marshallinseln",
    name_ar: "جزر مارشال",
    name_ru: "Маршалловы Острова",
    name_uk: "Маршалові Острови"
  },
  {
    code: "MQ",
    name_en: "Martinique",
    name_de: "Martinique",
    name_ar: "مارتينيك",
    name_ru: "Мартиника",
    name_uk: "Мартиника"
  },
  {
    code: "MR",
    name_en: "Mauritania",
    name_de: "Mauretanien",
    name_ar: "موريتانيا",
    name_ru: "Мавритания",
    name_uk: "Мавританія"
  },
  {
    code: "MU",
    name_en: "Mauritius",
    name_de: "Mauritius",
    name_ar: "موريشيوس",
    name_ru: "Маврикий",
    name_uk: "Маврикій"
  },
  {
    code: "YT",
    name_en: "Mayotte",
    name_de: "Mayotte",
    name_ar: "مايوت",
    name_ru: "Майотта",
    name_uk: "Майотта"
  },
  {
    code: "MX",
    name_en: "Mexico",
    name_de: "Mexiko",
    name_ar: "المكسيك",
    name_ru: "Мексика",
    name_uk: "Мексика"
  },
  {
    code: "FM",
    name_en: "Micronesia, Federated States of",
    name_de: "Mikronesien, Föderierte Staaten von",
    name_ar: "ولايات ميكرونيزيا الفيدرالية",
    name_ru: "Микронезия, Федеративные Штаты",
    name_uk: "Мікронезія, Федеративні Штати"
  },
  {
    code: "MD",
    name_en: "Moldova, Republic of",
    name_de: "Moldawien, Republik",
    name_ar: "جمهورية مولدوفا",
    name_ru: "Молдова, Республика",
    name_uk: "Молдова, Республіка"
  },
  {
    code: "MC",
    name_en: "Monaco",
    name_de: "Monaco",
    name_ar: "موناكو",
    name_ru: "Монако",
    name_uk: "Монако"
  },
  {
    code: "MN",
    name_en: "Mongolia",
    name_de: "Mongolei",
    name_ar: "منغوليا",
    name_ru: "Монголия",
    name_uk: "Монголія"
  },
  {
    code: "MS",
    name_en: "Montserrat",
    name_de: "Montserrat",
    name_ar: "مونتسيرات",
    name_ru: "Монтсеррат",
    name_uk: "Монтсеррат"
  },
  {
    code: "MA",
    name_en: "Morocco",
    name_de: "Marokko",
    name_ar: "المغرب",
    name_ru: "Марокко",
    name_uk: "Марокко"
  },
  {
    code: "MZ",
    name_en: "Mozambique",
    name_de: "Mosambik",
    name_ar: "موزمبيق",
    name_ru: "Мозамбик",
    name_uk: "Мозамбік"
  },
  {
    code: "MM",
    name_en: "Myanmar",
    name_de: "Myanmar",
    name_ar: "ميانمار",
    name_ru: "Мьянма",
    name_uk: "М'янма"
  },
  {
    code: "NA",
    name_en: "Namibia",
    name_de: "Namibia",
    name_ar: "ناميبيا",
    name_ru: "Намибия",
    name_uk: "Намібія"
  },
  {
    code: "NR",
    name_en: "Nauru",
    name_de: "Nauru",
    name_ar: "ناورو",
    name_ru: "Науру",
    name_uk: "Науру"
  },
  {
    code: "NP",
    name_en: "Nepal",
    name_de: "Nepal",
    name_ar: "نيبال",
    name_ru: "Непал",
    name_uk: "Непал"
  },
  {
    code: "NL",
    name_en: "Netherlands",
    name_de: "Niederlande",
    name_ar: "هولندا",
    name_ru: "Нидерланды",
    name_uk: "Нідерланди"
  },
  {
    code: "AN",
    name_en: "Netherlands Antilles",
    name_de: "Niederländische Antillen",
    name_ar: "جزر الأنتيل الهولندية",
    name_ru: "Нидерландские Антильские острова",
    name_uk: "Нідерландські Антильські острови"
  },
  {
    code: "NC",
    name_en: "New Caledonia",
    name_de: "Neukaledonien",
    name_ar: "كاليدونيا الجديدة",
    name_ru: "Новая Каледония",
    name_uk: "Нова Каледонія"
  },
  {
    code: "NZ",
    name_en: "New Zealand",
    name_de: "Neuseeland",
    name_ar: "نيوزيلندا",
    name_ru: "Новая Зеландия",
    name_uk: "Нова Зеландія"
  },
  {
    code: "NI",
    name_en: "Nicaragua",
    name_de: "Nicaragua",
    name_ar: "نيكاراغوا",
    name_ru: "Никарагуа",
    name_uk: "Нікарагуа"
  },
  {
    code: "NE",
    name_en: "Niger",
    name_de: "Niger",
    name_ar: "النيجر",
    name_ru: "Нигер",
    name_uk: "Нігер"
  },
  {
    code: "NG",
    name_en: "Nigeria",
    name_de: "Nigeria",
    name_ar: "نيجيريا",
    name_ru: "Нигерия",
    name_uk: "Нігерія"
  },
  {
    code: "NU",
    name_en: "Niue",
    name_de: "Niue",
    name_ar: "نيوي",
    name_ru: "Ниуэ",
    name_uk: "Ніуе"
  },
  {
    code: "NF",
    name_en: "Norfolk Island",
    name_de: "Norfolkinsel",
    name_ar: "جزيرة نورفولك",
    name_ru: "Остров Норфолк",
    name_uk: "Острів Норфолк"
  },
  {
    code: "MP",
    name_en: "Northern Mariana Islands",
    name_de: "Nördliche Marianen",
    name_ar: "جزر ماريانا الشمالية",
    name_ru: "Северные Марианские острова",
    name_uk: "Північні Маріанські острови"
  },
  {
    code: "NO",
    name_en: "Norway",
    name_de: "Norwegen",
    name_ar: "النرويج",
    name_ru: "Норвегия",
    name_uk: "Норвегія"
  },
  {
    code: "OM",
    name_en: "Oman",
    name_de: "Oman",
    name_ar: "عمان",
    name_ru: "Оман",
    name_uk: "Оман"
  },
  {
    code: "PK",
    name_en: "Pakistan",
    name_de: "Pakistan",
    name_ar: "باكستان",
    name_ru: "Пакистан",
    name_uk: "Пакистан"
  }
  ,
  {
    code: "PW",
    name_en: "Palau",
    name_de: "Palau",
    name_ar: "بالاو",
    name_ru: "Палау",
    name_uk: "Палау"
  },
  {
    code: "PS",
    name_en: "Palestinian Territory, Occupied",
    name_de: "Palästinensisches Gebiet, besetzt",
    name_ar: "الأراضي الفلسطينية المحتلة",
    name_ru: "Палестинская территория, оккупированная",
    name_uk: "Палестинська територія, окупована"
  },
  {
    code: "PA",
    name_en: "Panama",
    name_de: "Panama",
    name_ar: "بنما",
    name_ru: "Панама",
    name_uk: "Панама"
  },
  {
    code: "PG",
    name_en: "Papua New Guinea",
    name_de: "Papua-Neuguinea",
    name_ar: "بابوا غينيا الجديدة",
    name_ru: "Папуа - Новая Гвинея",
    name_uk: "Папуа-Нова Гвінея"
  },
  {
    code: "PY",
    name_en: "Paraguay",
    name_de: "Paraguay",
    name_ar: "باراغواي",
    name_ru: "Парагвай",
    name_uk: "Парагвай"
  },
  {
    code: "PE",
    name_en: "Peru",
    name_de: "Peru",
    name_ar: "بيرو",
    name_ru: "Перу",
    name_uk: "Перу"
  },
  {
    code: "PH",
    name_en: "Philippines",
    name_de: "Philippinen",
    name_ar: "الفلبين",
    name_ru: "Филиппины",
    name_uk: "Філіппіни"
  },
  {
    code: "PN",
    name_en: "Pitcairn",
    name_de: "Pitcairn",
    name_ar: "بيتكيرن",
    name_ru: "Питкэрн",
    name_uk: "Піткерн"
  },
  {
    code: "PL",
    name_en: "Poland",
    name_de: "Polen",
    name_ar: "بولندا",
    name_ru: "Польша",
    name_uk: "Польща"
  },
  {
    code: "PT",
    name_en: "Portugal",
    name_de: "Portugal",
    name_ar: "البرتغال",
    name_ru: "Португалия",
    name_uk: "Португалія"
  },
  {
    code: "PR",
    name_en: "Puerto Rico",
    name_de: "Puerto Rico",
    name_ar: "بورتو ريكو",
    name_ru: "Пуэрто-Рико",
    name_uk: "Пуерто-Ріко"
  },
  {
    code: "QA",
    name_en: "Qatar",
    name_de: "Katar",
    name_ar: "قطر",
    name_ru: "Катар",
    name_uk: "Катар"
  },
  {
    code: "RE",
    name_en: "Reunion",
    name_de: "Réunion",
    name_ar: "ريونيون",
    name_ru: "Реюньон",
    name_uk: "Реюньйон"
  },
  {
    code: "RO",
    name_en: "Romania",
    name_de: "Rumänien",
    name_ar: "رومانيا",
    name_ru: "Румыния",
    name_uk: "Румунія"
  },
  {
    code: "RU",
    name_en: "Russian Federation",
    name_de: "Russische Föderation",
    name_ar: "الاتحاد الروسي",
    name_ru: "Российская Федерация",
    name_uk: "Російська Федерація"
  },
  {
    code: "RW",
    name_en: "Rwanda",
    name_de: "Ruanda",
    name_ar: "رواندا",
    name_ru: "Руанда",
    name_uk: "Руанда"
  },
  {
    code: "SH",
    name_en: "Saint Helena",
    name_de: "St. Helena",
    name_ar: "سانت هيلينا",
    name_ru: "Святая Елена",
    name_uk: "Святая Елена"
  },
  {
    code: "KN",
    name_en: "Saint Kitts and Nevis",
    name_de: "St. Kitts und Nevis",
    name_ar: "سانت كيتس ونيفيس",
    name_ru: "Сент-Китс и Невис",
    name_uk: "Сент-Кітс і Невіс"
  },
  {
    code: "LC",
    name_en: "Saint Lucia",
    name_de: "St. Lucia",
    name_ar: "سانت لوسيا",
    name_ru: "Сент-Люсия",
    name_uk: "Сент-Люсія"
  },
  {
    code: "PM",
    name_en: "Saint Pierre and Miquelon",
    name_de: "Saint-Pierre und Miquelon",
    name_ar: "سانت بيير وميكولون",
    name_ru: "Сен-Пьер и Микелон",
    name_uk: "Сен-П'єр і Мікелон"
  },
  {
    code: "VC",
    name_en: "Saint Vincent and the Grenadines",
    name_de: "Saint Vincent und die Grenadinen",
    name_ar: "سانت فنسنت والغرينادين",
    name_ru: "Сент-Винсент и Гренадины",
    name_uk: "Сент-Вінсент і Гренадини"
  },
  {
    code: "WS",
    name_en: "Samoa",
    name_de: "Samoa",
    name_ar: "ساموا",
    name_ru: "Самоа",
    name_uk: "Самоа"
  },
  {
    code: "SM",
    name_en: "San Marino",
    name_de: "San Marino",
    name_ar: "سان مارينو",
    name_ru: "Сан-Марино",
    name_uk: "Сан-Марино"
  },
  {
    code: "ST",
    name_en: "Sao Tome and Principe",
    name_de: "São Tomé und Príncipe",
    name_ar: "ساو تومي وبرينسيب",
    name_ru: "Сан-Томе и Принсипи",
    name_uk: "Сан-Томе і Принсіпі"
  },
  {
    code: "SA",
    name_en: "Saudi Arabia",
    name_de: "Saudi-Arabien",
    name_ar: "المملكة العربية السعودية",
    name_ru: "Саудовская Аравия",
    name_uk: "Саудівська Аравія"
  },
  {
    code: "SN",
    name_en: "Senegal",
    name_de: "Senegal",
    name_ar: "السنغال",
    name_ru: "Сенегал",
    name_uk: "Сенегал"
  },
  {
    code: "CS",
    name_en: "Serbia and Montenegro",
    name_de: "Serbien und Montenegro",
    name_ar: "صربيا والجبل الأسود",
    name_ru: "Сербия и Черногория",
    name_uk: "Сербія та Чорногорія"
  },
  {
    code: "SC",
    name_en: "Seychelles",
    name_de: "Seychellen",
    name_ar: "سيشيل",
    name_ru: "Сейшельские Острова",
    name_uk: "Сейшельські Острови"
  },
  {
    code: "SL",
    name_en: "Sierra Leone",
    name_de: "Sierra Leone",
    name_ar: "سيراليون",
    name_ru: "Сьерра-Леоне",
    name_uk: "Сьєрра-Леоне"
  },
  {
    code: "SG",
    name_en: "Singapore",
    name_de: "Singapur",
    name_ar: "سنغافورة",
    name_ru: "Сингапур",
    name_uk: "Сінгапур"
  },
  {
    code: "SK",
    name_en: "Slovakia",
    name_de: "Slowakei",
    name_ar: "سلوفاكيا",
    name_ru: "Словакия",
    name_uk: "Словаччина"
  },
  { code: "SI", name_en: "Slovenia", name_de: "Slowenien", name_ar: "سلوفينيا", name_ru: "Словения", name_uk: "Словенія" },
  { code: "SB", name_en: "Solomon Islands", name_de: "Salomonen", name_ar: "جزر سليمان", name_ru: "Соломоновы Острова", name_uk: "Соломонові острови" },
  { code: "SO", name_en: "Somalia", name_de: "Somalia", name_ar: "الصومال", name_ru: "Сомали", name_uk: "Сомалія" },
  { code: "ZA", name_en: "South Africa", name_de: "Südafrika", name_ar: "جنوب أفريقيا", name_ru: "Южноафриканская Республика", name_uk: "Південноафриканська Республіка" },
  { code: "GS", name_en: "South Georgia and the South Sandwich Islands", name_de: "Südgeorgien und die Südlichen Sandwichinseln", name_ar: "جورجيا الجنوبية وجزر ساندويتش الجنوبية", name_ru: "Южная Георгия и Южные Сандвичевы острова", name_uk: "Південна Георгія та Південні Сандвічеві острови" },
  { code: "ES", name_en: "Spain", name_de: "Spanien", name_ar: "إسبانيا", name_ru: "Испания", name_uk: "Іспанія" },
  { code: "LK", name_en: "Sri Lanka", name_de: "Sri Lanka", name_ar: "سريلانكا", name_ru: "Шри-Ланка", name_uk: "Шрі-Ланка" },
  { code: "SD", name_en: "Sudan", name_de: "Sudan", name_ar: "السودان", name_ru: "Судан", name_uk: "Судан" },
  { code: "SR", name_en: "Suriname", name_de: "Surinam", name_ar: "سورينام", name_ru: "Суринам", name_uk: "Суринам" },
  { code: "SJ", name_en: "Svalbard and Jan Mayen", name_de: "Svalbard und Jan Mayen", name_ar: "سفالبارد وجزر يان ماين", name_ru: "Шпицберген и Ян-Майен", name_uk: "Шпіцберген і Ян-Маєн" },
  { code: "SZ", name_en: "Swaziland", name_de: "Swasiland", name_ar: "سوازيلاند", name_ru: "Свазиленд", name_uk: "Свазіленд" },
  { code: "SE", name_en: "Sweden", name_de: "Schweden", name_ar: "السويد", name_ru: "Швеция", name_uk: "Швеція" },
  { code: "CH", name_en: "Switzerland", name_de: "Schweiz", name_ar: "سويسرا", name_ru: "Швейцария", name_uk: "Швейцарія" },
  { code: "SY", name_en: "Syrian Arab Republic", name_de: "Syrische Arabische Republik", name_ar: "الجمهورية العربية السورية", name_ru: "Сирийская Арабская Республика", name_uk: "Сирійська Арабська Республіка" },
  { code: "TW", name_en: "Taiwan, Province of China", name_de: "Taiwan, Provinz China", name_ar: "تايوان، محافظة الصين", name_ru: "Тайвань, провинция Китая", name_uk: "Тайвань, провінція Китаю" },
  { code: "TJ", name_en: "Tajikistan", name_de: "Tadschikistan", name_ar: "طاجيكستان", name_ru: "Таджикистан", name_uk: "Таджикистан" },
  { code: "TZ", name_en: "Tanzania, United Republic of", name_de: "Tansania, Vereinigte Republik", name_ar: "تنزانيا، جمهورية الاتحاد", name_ru: "Танзания, Объединенная Республика", name_uk: "Танзанія, Об'єднана Республіка" },
  { code: "TH", name_en: "Thailand", name_de: "Thailand", name_ar: "تايلاند", name_ru: "Таиланд", name_uk: "Таїланд" },
  { code: "TL", name_en: "Timor-Leste", name_de: "Osttimor", name_ar: "تيمور الشرقية", name_ru: "Восточный Тимор", name_uk: "Східний Тимор" },
  { code: "TG", name_en: "Togo", name_de: "Togo", name_ar: "توغو", name_ru: "Того", name_uk: "Того" },
  { code: "TK", name_en: "Tokelau", name_de: "Tokelau", name_ar: "توكيلاو", name_ru: "Токелау", name_uk: "Токелау" },
  { code: "TO", name_en: "Tonga", name_de: "Tonga", name_ar: "تونغا", name_ru: "Тонга", name_uk: "Тонга" },
  { code: "TT", name_en: "Trinidad and Tobago", name_de: "Trinidad und Tobago", name_ar: "ترينيداد وتوباغو", name_ru: "Тринидад и Тобаго", name_uk: "Тринідад і Тобаго" },
  { code: "TN", name_en: "Tunisia", name_de: "Tunesien", name_ar: "تونس", name_ru: "Тунис", name_uk: "Туніс" },
  { code: "TR", name_en: "Turkey", name_de: "Türkei", name_ar: "تركيا", name_ru: "Турция", name_uk: "Туреччина" },
  { code: "TM", name_en: "Turkmenistan", name_de: "Turkmenistan", name_ar: "تركمانستان", name_ru: "Туркмения", name_uk: "Туркменістан" },
  { code: "TC", name_en: "Turks and Caicos Islands", name_de: "Turks- und Caicosinseln", name_ar: "جزر تركس وكايكوس", name_ru: "Острова Теркс и Кайкос", name_uk: "Острови Теркс і Кайкос" },
  { code: "TV", name_en: "Tuvalu", name_de: "Tuvalu", name_ar: "توفالو", name_ru: "Тувалу", name_uk: "Тувалу" },
  { code: "UG", name_en: "Uganda", name_de: "Uganda", name_ar: "أوغندا", name_ru: "Уганда", name_uk: "Уганда" },
  { code: "UA", name_en: "Ukraine", name_de: "Ukraine", name_ar: "أوكرانيا", name_ru: "Украина", name_uk: "Україна" },
  { code: "AE", name_en: "United Arab Emirates", name_de: "Vereinigte Arabische Emirate", name_ar: "الإمارات العربية المتحدة", name_ru: "Объединенные Арабские Эмираты", name_uk: "Об'єднані Арабські Емірати" },
  { code: "GB", name_en: "United Kingdom", name_de: "Vereinigtes Königreich", name_ar: "المملكة المتحدة", name_ru: "Великобритания", name_uk: "Сполучене Королівство" },
  { code: "US", name_en: "United States", name_de: "Vereinigte Staaten", name_ar: "الولايات المتحدة", name_ru: "Соединенные Штаты", name_uk: "Сполучені Штати" },
  { code: "UM", name_en: "United States Minor Outlying Islands", name_de: "Vereinigte Staaten von Amerika (Übrige Inseln)", name_ar: "جزر الولايات المتحدة الأمريكية النائية", name_ru: "Малые отдаленные острова США", name_uk: "Малі віддалені острови США" },
  { code: "UY", name_en: "Uruguay", name_de: "Uruguay", name_ar: "أوروغواي", name_ru: "Уругвай", name_uk: "Уругвай" },
  { code: "UZ", name_en: "Uzbekistan", name_de: "Usbekistan", name_ar: "أوزبكستان", name_ru: "Узбекистан", name_uk: "Узбекистан" },
  { code: "VU", name_en: "Vanuatu", name_de: "Vanuatu", name_ar: "فانواتو", name_ru: "Вануату", name_uk: "Вануату" },
  { code: "VE", name_en: "Venezuela", name_de: "Venezuela", name_ar: "فنزويلا", name_ru: "Венесуэла", name_uk: "Венесуела" },
  { code: "VN", name_en: "Viet Nam", name_de: "Vietnam", name_ar: "فيتنام", name_ru: "Вьетнам", name_uk: "В'єтнам" },
  { code: "VG", name_en: "Virgin Islands, British", name_de: "Britische Jungferninseln", name_ar: "جزر فيرجن البريطانية", name_ru: "Британские Виргинские острова", name_uk: "Британські Віргінські острови" },
  { code: "VI", name_en: "Virgin Islands, U.S.", name_de: "Amerikanische Jungferninseln", name_ar: "جزر فيرجن الأمريكية", name_ru: "Виргинские острова США", name_uk: "Американські Віргінські острови" },
  { code: "WF", name_en: "Wallis and Futuna", name_de: "Wallis und Futuna", name_ar: "واليس وفوتونا", name_ru: "Уолліс и Футуна", name_uk: "Уолліс і Футуна" },
  { code: "EH", name_en: "Western Sahara", name_de: "Westliche Sahara", name_ar: "الصحراء الغربية", name_ru: "Западная Сахара", name_uk: "Західна Сахара" },
  { code: "YE", name_en: "Yemen", name_de: "Jemen", name_ar: "اليمن", name_ru: "Йемен", name_uk: "Ємен" },
  { code: "ZM", name_en: "Zambia", name_de: "Sambia", name_ar: "زامبيا", name_ru: "Замбия", name_uk: "Замбія" },
  { code: "ZW", name_en: "Zimbabwe", name_de: "Simbabwe", name_ar: "زيمبابوي", name_ru: "Зимбабве", name_uk: "Зімбабве" }
]