import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: {
    create: false,
    update: false,
    remove: false,
    getAll: false,
    getAllDraft: false,
  },
  records: null,
  count: null,
  getAllDraftRecords: null,
  getAllDraftCount: null,
};


export const contentSlice = createSlice({
  name: "content",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      const {
        getAll = null,
        getAllDraft=null,
        create = null,
        update = null,
        remove = null,
      } = action.payload;
      state.loading = {
        getAll: getAll != null ? getAll : state.loading?.getAll || false,
        getAllDraft: getAllDraft != null ? getAllDraft : state.loading?.getAllDraft || false,
        create: create != null ? create : state.loading.create || false,
        update: update != null ? update : state.loading.update || false,
        remove: remove != null ? remove : state.loading?.remove || false,
      };
    },
    getAll: (state, action) => {
      const { records, count } = action.payload;
      state.records = records;
      state.count = count;
    },
    getAllDraft: (state, action) => {
      const { records, count } = action.payload;
      state.getAllDraftRecords = records;
      state.getAllDraftCount = count;
    },
  },
});
