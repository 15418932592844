import { Pagination, Table } from "antd";
import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  ApproveButton,
  RejectButton,
  ViewButton,
  EditButton
} from "components/button/Buttons";
import { DeleteConfirmationDialog } from "components/delete-confirmation-dialog/DeleteConfirmationDialog";
import * as actions from "redux/content/Actions";

function DraftContentTable(props) {
  const {
    setView,
    setDrawer,
    editRecord,
    setEditRecord,
    rejectRecord,
    setRejectRecord,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
  } = props;

  const dispatch = useDispatch();
  const pagination = getPagination();

  const { user,getAllDraftRecords, getAllDraftCount, loading } = useSelector(
    (state) => ({
      
      user: state.auth.user || null,
      getAllDraftRecords: state.content?.getAllDraftRecords || [],
      getAllDraftCount: state.content?.getAllDraftCount || 0,
      loading: state.content?.loading,
    }),
    shallowEqual
  );

  const fetchContent = useCallback(() => {
    dispatch(actions.getAllDraft(pagination));
  },
  // eslint-disable-next-line
  [pageSize, pageNumber, searchText, dispatch]);

  useEffect(() => {
    fetchContent();
  }, 
  // eslint-disable-next-line
  [dispatch, pageSize, pageNumber, searchText]);
 
  const RejectAcceptTheRecord = (entity,dialog) => {
    setRejectRecord(dialog)
    setEditRecord(entity)
  };
  
  const handleReject = () => {
    const values={
      "isApproved": false,
      "parentId": editRecord.parentId
  }  
    dispatch(actions.updateDraftStatus(editRecord.id, values, setRejectRecord, pagination));
  };
  const handleApprove= () => {
    const values={
      "isApproved": true,
      "parentId": editRecord.parentId
  }  
    dispatch(actions.updateDraftStatus(editRecord.id, values, setRejectRecord, pagination));
  };

  const columns = [
    {
      title: (
        <>
          Meta Title <span className="form-label-secondary">(English)</span>
        </>
      ),
      dataIndex: "metaTitle_en",
      key: "metaTitle_en",
      render: (text_en, entity) => {
        return <>{entity?.metaTitle_en}</>;
      },
    },
    {
      title: (
        <>
          Meta Keyword <span className="form-label-secondary">(English)</span>
        </>
      ),
      dataIndex: "metaKeyword_en",
      key: "metaKeyword_en",
      render: (text_en, entity) => {
        return <>{entity?.metaKeyword_en}</>;
      },
    },

    {
      title: "Actions",
      dataIndex: "name_de",
      key: "name_de",
      width: "200px",
      render: (name_en, entity) => {
        return (
          <>
            <div className="d-flex mx-auto ">.
            {(user.role === 'admin' || user.role === 'author' || user.role === 'editor') && (
              <span className="nav-link">
                <ViewButton
                  onClick={() => {
                    setView(true);
                    setEditRecord(entity);
                    setDrawer(true);
                  }}
                />
              </span>
              )}
          {(user.role === 'admin' || user.role === 'author' || user.role === 'editor') && (
              <span className="nav-link mx-1">
                <EditButton
                  onClick={() => {
                    setEditRecord(entity);
                    setDrawer(true);
                  }}
                />
              </span>
              )}
              {(user.role === 'admin' || user.role === 'editor') && (
                <>              <span className="nav-link mx-1">
                <ApproveButton
                  onClick={() => {
                    RejectAcceptTheRecord(entity,'approve');
                  }}
                />
              </span>
           
              <span className="nav-link ">
                <RejectButton onClick={() => RejectAcceptTheRecord(entity,'reject')} />
              </span>
              </>

              )}
            </div>
          </>
        );
      },
    },
  ];

  const paginationHandler = (current, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(current);
  };

  return (
    <>
      <div className="pb-2 pt-3 table_main_div " style={{ height: "contain" }}>
        <div className="row " style={{ paddingRight: "50px" }}>
          <div className="d-grid d-sm-flex gap-3 justify-content-between">
            <div className=" mx-4 ">
              <h3 className="  pt-3">Total - {getAllDraftCount}</h3>
            </div>
            <div
              className="input-group input-group-merge input-group-flush mr-4 ml-2 "
              style={{ width: "180px" }}
            >
              <div className="input-group-prepend input-group-text">
                <i className="bi-search"></i>
              </div>
              <input
                onChange={(e) => setSearchText(e.target.value)}
                id="datatableSearch"
                type="search"
                className="form-control"
                placeholder="Search "
                aria-label="Search "
              />
            </div>
            {/* <!-- End Search --> */}
          </div>
        </div>

        <div className="domain_history table_main py-3 px-2">
          <Table
            className="table_tag"
            size="small"
            loading={loading?.getAll || false}
            // scroll={{ y: "calc(100vh - 420px)" }}
            scroll={{ x: 'max-content' }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  // navigate(`/countries/${record?.id}`);
                  // navigateTotrafficPage(record, event);
                },
              };
            }}
            pagination={false}
            dataSource={getAllDraftRecords}
            columns={columns}
          />
          <div
            className="d-flex mt-3 mr-5 justify-content-end"
            style={{ marginRight: "20px" }}
          >
            <Pagination
              showSizeChanger
              onChange={paginationHandler}
              onShowSizeChange={paginationHandler}
              pageSize={pageSize}
              defaultCurrent={1}
              total={getAllDraftCount}
            />
          </div>
        </div>
      </div>
      {rejectRecord==='approve' &&
      <DeleteConfirmationDialog
        loading={loading?.remove}
        open={rejectRecord}
        onClose={setRejectRecord}
        onDelete={handleApprove}
        title="Approve"
        content="Are you sure you want to Approve it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Approve"
      />
    }
      {rejectRecord==='reject' &&

      <DeleteConfirmationDialog
        loading={loading?.remove}
        open={rejectRecord}
        onClose={setRejectRecord}
        onDelete={handleReject}
        title="Reject"
        content="Are you sure you want to Reject it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Reject"
      />
}
    </>
  );
}

export default DraftContentTable;
