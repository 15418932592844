import { Drawer, Space, Spin, Tabs, notification,Tooltip  } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import * as actions from "redux/facility/Actions";
import * as uploadActions from "redux/upload/Actions";
import BasicInfo from "./components/basic-info";
import FacilityDoctors from "./components/facility-doctors";
import FacilityDepartments from "./components/facility-departments";
import FacilitySpecilities from "./components/facility-Specilities";
import FAQs from "./components/faqs";
import Prices from "./components/prices";
import Services from "./components/services";
import WhyChooseUs from "./components/why-choose-us";
import * as requestFacilityRelations from "./../../../redux/facility/Crud"; //Achievements,media,slug with relation passing to facility api
import * as requestFacilityWhyChooseUs from "./../../../redux/common/why-choose-us/Crud";
import * as requestFacilityServicePrice from "./../../../redux/common/service-price/Crud";
import * as requestFacilityService from "./../../../redux/common/service/Crud";
import * as requestFacilityDoctor from "./../../../redux/doctor/Crud";
import * as requestFacilityFaq from "./../../../redux/common/faqs/Crud";
import * as requestFacilityDepartment from "./../../../redux/common/facility-department/Crud";
import * as requestFacilitySpeciality from "./../../../redux/common/facility-speciality/Crud";
import * as requestFacilityServiceLanguage from "./../../../redux/common/service-language/Crud";
import * as FacilityDepartmentActions from "redux/common/facility-department/Actions";
import { SaveConfirmationDialog } from "components/save-confirmation-dialog/SaveConfirmationDialog";
import * as FacilitySpecialityActions from "redux/common/facility-speciality/Actions";
import { createQueryParams } from "utils/helpers/helper-functions";
import { format } from "date-fns";

function FacilityDrawer(props) {
  const {
    setDrawer,
    drawer,
    getPagination,
    view,
    setView,
    recordId,
    setRecordId,
  } = props;

  const [editRecord, setEditRecord] = useState(null);
  const [itemLoaded, setItemLoaded] = useState(false);
  const [selectedTab, setSelectedTab] = useState("1");
  const [open, setOpen] = useState(false);

// This will reset the selected tab to "Basic Info" when the drawer opens
useEffect(() => {
  if (drawer) {
    setSelectedTab("1"); // Reset to Basic Info tab
  }
}, [drawer]);
  const { loading } = useSelector(
    (state) => ({
      loading: state.facility?.loading,
    }),
    shallowEqual
  );
  /********************* DEFAUL || INITAL DATA **********************/
  const {
    name_en = "",
    name_ar = "",
    name_ru = "",
    name_de = "",
    name_uk = "",
    address_en = "",
    address_ar = "",
    address_ru = "",
    address_de = "",
    address_uk = "",
    lat = null,
    long = null,
    zipCode = 0,
    description_en = "",
    description_ar = "",
    description_ru = "",
    description_de = "",
    description_uk = "",
    descriptionMeta = "",
    email = "",
    phone = "",
    type = null,
    isFeatured = false,
    isActive = false,
    cityId = null,
    countryId = null,
    foundation,
    department,
    bed,
    doctor,
    yearly_patient,
    id = null,
    overview_en,
    overview_de,
    overview_ar,
    overview_ru,
    overview_uk,
  } = editRecord || {};
  const isEditing = !view;
  const isLoading = isEditing ? loading.update : loading.create;
  let title = "";
  if (view) title = "View";
  if (isEditing) title = "Add";
  if (isEditing && editRecord) title = "Edit";

  title = title + " Facility";

  const dispatch = useDispatch();
  const pagination = getPagination();

  const setEditDefaultValues = () => {
    const media = Array.isArray(editRecord?.media) ? 
    editRecord.media.map(({ url }, index) => ({
        uid: index + 1,
        name: "Name",
        status: "done",
        url,
    })) : []; 

const achievements = Array.isArray(editRecord?.achievements) ? 
editRecord?.achievements?.map(
    ({ achievement: url }, index) => ({
        uid: index + 1,
        name: "Name",
        status: "done",
        url,
  })) : [];


const doctorIds =  Array.isArray(editRecord?.facilityDoctor) ? 
editRecord?.facilityDoctor?.map((doctor, index) => ({
    value: doctor?.id,
    label:doctor?.firstName_en || "",
    index,
  })) : [];

const servicePriceIds = Array.isArray(editRecord?.facilityServicePrice) ? 
editRecord?.facilityServicePrice?.map(
    (servicePrice, index) => ({
        value: servicePrice?.id,
        label:  servicePrice?.name_en || "",
        index,
      })) : [];

const serviceIds = Array.isArray(editRecord?.facilityServicePrice) ? 
editRecord?.facilityService?.map((service, index) => ({
    value: service?.id,
    label: service?.name_en || "",
    index,
  })) : [];
  
const specialityIds =  Array.isArray(editRecord?.facilitySpeciality) ? 
editRecord?.facilitySpeciality?.map((speciality, index) => {
    return {
      value: speciality?.id,
      label: speciality?.name_en || "",
      index,
    };
  }) : [];

const serviceLanguageIds = Array.isArray(editRecord?.facilityServiceLanguage) ? 
editRecord?.facilityServiceLanguage?.map(
    (serviceLanguage, index) => ({
        value: serviceLanguage?.id,
        label: serviceLanguage?.name_en || "",
        index,

  })) : [];

    const facilityType = {
      value: type,
      label: type,
      index: 0,
    };

    formik.setValues({
      ...initialValues,
      mediaUrls: media,
      achievements,
      doctorIds,
      servicePriceIds,
      serviceIds,
      specialityIds,
      serviceLanguageIds,
      type: facilityType,
    });
  };

  const resetForm = () => formik.resetForm();

  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      setView(false);
      setEditRecord(null);
      setRecordId(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  

const getRecordDataAndSetValuesForViewingOrEditing = async (recordId) => {
  try {
    // Initialize responseData with empty arrays for all relations
    let responseData = {
      achievements: [],
      facilityWhyChooseUs: [],
      facilityServicePrice: [],
      facilityService: [],
      facilityDoctor: [],
      facilityFaq: [],
      facilityDepartment: [],
      facilitySpeciality: [],
      facilityServiceLanguage: []
    };

    // Create queries for all relations
    const queries = [
      requestFacilityRelations.getAllAchievements({ query: `where[facilityId]=${recordId}` }),
      requestFacilityWhyChooseUs.getAll({ query: `where[facilityWhyChooseUs][facilityId]=${recordId}` }),
      requestFacilityServicePrice.getAll({ query: `where[facilityServicePrice][facilityId]=${recordId}` }),
      requestFacilityService.getAll({ query: `where[facilityService][facilityId]=${recordId}` }),
      requestFacilityDoctor.getAll({ query: `where[facilityDoctor][facilityId]=${recordId}` }),
      requestFacilityFaq.getAll({ query: `where[facilityFaq][facilityId]=${recordId}` }),
      requestFacilityDepartment.getAll({ query: `where[facilityDepartment][facilityId]=${recordId}` }),
      requestFacilitySpeciality.getAll({ query: `where[facilitySpeciality][facilityId]=${recordId}` }),
      requestFacilityServiceLanguage.getAll({ query: `where[facilityServiceLanguage][facilityId]=${recordId}` })
    ];

    const responses = await Promise.all(queries);

    // Map response keys to their corresponding property in responseData
    const responseMap = {
      0: 'achievements',
      1: 'facilityWhyChooseUs',
      2: 'facilityServicePrice',
      3: 'facilityService',
      4: 'facilityDoctor',
      5: 'facilityFaq',
      6: 'facilityDepartment',
      7: 'facilitySpeciality',
      8: 'facilityServiceLanguage'
    };

    responses.forEach((response, index) => {
      if (response.status === 200 && response.data.records?.length) {
        const key = responseMap[index];
        if (key) {
          responseData[key] = responseData[key].concat(response.data.records);
        } 
      } 
    });

      const query = createQueryParams(
          1,
          0,
          null,
          ["city","media"],
          `where[id]=${recordId}`
        );
        let response = await requestFacilityRelations.getAll({ query });
        if (response.status === 200 && response.data.records?.length) {
            responseData = { ...responseData, ...response.data.records[0] };
        }
      
    setEditRecord(responseData);
    setItemLoaded(true);
  } catch (error) {
    console.error('Error fetching record data:', error);
    notification.error({ description: 'Sorry! Something went wrong' });
  }
};



  useEffect(() => {
    setEditDefaultValues();
  }, 
  // eslint-disable-next-line
  [editRecord]);

  const getFacilityDepartments = async (recordId) => {
    try {
      dispatch(FacilityDepartmentActions.getAll({ query: `limit=1000000&offset=0&where[facilityDepartment][facilityId]=${recordId}` }))
    } catch (error) {
        notification.error({ description: "Sorry! Something went wrong" });
    }
  };

  const getFacilitySpeciality = async (recordId) => {
    try {
        const facilitySpecialityQuery=`limit=1000000&offset=0&relations=speciality&where[facilityId]=${recordId}`
        dispatch(FacilitySpecialityActions.getAllFaciltiesSpecialities({ query: facilitySpecialityQuery }))
    } catch (error) {
        notification.error({ description: "Sorry! Something went wrong" });
    }
  };
  
  useEffect(() => {
    // Only call getMasterData when the component mounts
    if (drawer && recordId) {
      getRecordDataAndSetValuesForViewingOrEditing(recordId);
      getFacilitySpeciality(recordId)
      getFacilityDepartments(recordId)
    }
  }, 
  // eslint-disable-next-line
  [drawer]);

  //formik Handler
  const initialValues = {
    id:id||null,
    name_en: name_en || "",
    name_ar: name_ar || "",
    name_ru: name_ru || "",
    name_de: name_de || "",
    name_uk: name_uk || "",
    address_en: address_en || "",
    address_ar: address_ar || "",
    address_ru: address_ru || "",
    address_de: address_de || "",
    address_uk: address_uk || "",

    description_en: description_en || "",
    description_ar: description_ar || "",
    description_ru: description_ru || "",
    description_de: description_de || "",
    description_uk: description_uk || "",

    descriptionMeta: descriptionMeta || "",

    email: email || "",
    phone: phone || "",
    lat: lat || "",
    long: long || "",
    zipCode: zipCode || "",
    type: type || "",
    isFeatured: isFeatured || false,
    isActive: isActive || false,
    cityId: cityId || "",
    countryId: countryId || "",
    mediaUrls: [],

    // About
    foundation: foundation || "1999",
    department: department || "0",
    bed: bed || "0",
    doctor: doctor || "0",
    yearly_patient: yearly_patient || "0",

    // OverView
    overview_en: overview_en || "",
    overview_de: overview_de || "",
    overview_ar: overview_ar || "",
    overview_ru: overview_ru || "",
    overview_uk: overview_uk || "",
  
    doctorIds: [],
    servicePriceIds: [],
    serviceIds: [],
    serviceLanguageIds: [],
    specialityIds: [],
    achievements: [],
  };

  const validationSchema = Yup.object().shape({
    name_en: Yup.string().required("First Name (English) is required"),
    name_ar: Yup.string().notRequired(),
    name_ru: Yup.string().notRequired(),
    name_de: Yup.string().notRequired(),
    name_uk: Yup.string().notRequired(),


    address_en: Yup.string().required("Address (English) is required"),
    address_ar: Yup.string().notRequired(),
    address_ru: Yup.string().notRequired(),
    address_de: Yup.string().notRequired(),
    address_uk: Yup.string().notRequired(),

    lat: Yup.string().notRequired("Latitude is required"),
    long: Yup.string().notRequired("Longitude is required"),
    zipCode: Yup.string().notRequired(),
    countryId: Yup.string().required("Country is required"),
    cityId: Yup.string().required("City is required"),

    isFeatured: Yup.boolean().notRequired(),
    isActive: Yup.boolean().notRequired(),

    phone: Yup.string().notRequired(),
    email: Yup.string().notRequired(),
    type: Yup.object().required("Facility Type is required"),

    mediaUrls: Yup.array().notRequired(),
    achievements: Yup.array().notRequired(),

    // About
    foundation: Yup.number()
      .typeError("Must be a number")
      .min(1100)
      .max(format(new Date(), "yyyy"))
      .required("Year of Foundation is required"),
    department: Yup.number()
      .typeError("Must be a number")
      .required("No. of Departments is required"),
    bed: Yup.number()
      .typeError("Must be a number")
      .required("No. of Beds is required"),
    doctor: Yup.number()
      .typeError("Must be a number")
      .required("No. of  Doctors Patient is required"),
    yearly_patient: Yup.number()
      .typeError("Must be a number")
      .required("No. of  Yearly Patients is required"),

    // OverView
    overview_en: Yup.string().required("Overview (English) is required"),
    overview_de: Yup.string(),
    overview_ar: Yup.string(),
    overview_ru: Yup.string(),
    overview_uk: Yup.string(),

    doctorIds: Yup.array().notRequired(),
    servicePriceIds: Yup.array().notRequired(),
    serviceIds: Yup.array().notRequired(),
    serviceLanguageIds: Yup.array().notRequired(),
    specialityIds: Yup.array().notRequired(),
  });

  const ImageUpload = async (fileList) => {
    const oldImages = fileList
      .filter((file) => file?.url)
      .map(({ url }) => url);
    const NewImages = fileList.filter((file) => file?.originFileObj);

    if (NewImages?.length) {
      const Images = await dispatch(uploadActions.upload(NewImages));
      if (!Images?.length) {
        notification.success({ description: "Image Upload Failed" });
        return;
      }
      return [...Images, ...oldImages];
    }
    return oldImages;
  };


  // with removing the specilityIds
  const onSubmit = async (values) => {
    setOpen(false)
    const serviceIds = values.serviceIds?.map(({ value: id }) => id);
    const serviceLanguageIds = values.serviceLanguageIds?.map(({ value: id }) => id);
    const servicePriceIds = values.servicePriceIds?.map(({ value: id }) => id);
    const specialityIds = values.specialityIds?.map(({ value: id }) => id);
    const doctorIds = values.doctorIds?.map(({ value: id }) => id);
    const [mediaUrls, achievements] = await Promise.all([
      ImageUpload(values.mediaUrls),
      ImageUpload(values.achievements),
    ]);
  
    // Create a new payload object without `specialityIds`
    const { specialityIds: _, ...cleanPayload } = {
      ...values,
      specialityIds,
      doctorIds,
      servicePriceIds,
      serviceLanguageIds,
      serviceIds,
      type: values.type.value,
      mediaUrls,
      achievements,
    };
  
    const action = recordId
      ? actions.update(id, cleanPayload, setDrawer, resetForm, pagination)
      : actions.create(cleanPayload, setDrawer, resetForm, pagination);
  
    return dispatch(action);
  };
  
  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  const items = [
    {
      key: "1",
      label: "Basic Info",
      disabled: false,
      children: <BasicInfo {...{ formik, view }} />,
    },
    {
      key: "2",
      label: " Doctors",
      disabled: false,
      children: <FacilityDoctors {...{ formik, view }} />,
    },
    {
      key: "3",
      label: "Services",
      children: <Services {...{ formik, view }} />,
    },
    {
      key: "4",
      label: recordId === null ? (
        <Tooltip title="This option will be available after creating the facility, while editing.">
          Departments
        </Tooltip>
      ) : (
        "Departments"
      ),
      disabled: recordId === null,
      children: <FacilityDepartments {...{ formik, view,recordId }} />,
    },
    {
      key: "5",
      label: recordId === null ? (
        <Tooltip title="This option will be available after creating the facility, while editing.">
          Specilities
        </Tooltip>
      ) : (
        "Specilities"
      ),
      disabled: recordId === null,
      children: <FacilitySpecilities {...{ formik, view }} />,
    },
    {
      key: "6",
      label: recordId === null ? (
        <Tooltip title="This option will be available after creating the facility, while editing.">
          Why Choose Us
        </Tooltip>
      ) : (
        "Why Choose Us"
      ),
      disabled: recordId === null,
      children: <WhyChooseUs {...{ formik, view, recordId }} />,
    },
   
    {
      key: "7",
      label: recordId === null ? (
        <Tooltip title="This option will be available after creating the facility, while editing.">
          Prices
        </Tooltip>
      ) : (
        "Prices"
      ),

      disabled: recordId === null,
      children: <Prices {...{ formik, view }} />,
    },
   
    {
      key: "8",
      label: recordId === null ? (
        <Tooltip title="This option will be available after creating the facility, while editing.">
          FAQS
        </Tooltip>
      ) : (
        "FAQS"
      ),
      disabled: recordId === null,
      children: <FAQs {...{ formik, view , recordId}} />,
    },
   
    
  ];

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"85%"}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!editRecord && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={async () => {
                    const errors = await formik.validateForm(); // Trigger validation
                    if (Object.keys(errors).length === 0) { // Check if there are no validation errors
                      setOpen(true); // Open confirmation dialog if form is valid
                    } else {
                      formik.setTouched(
                        Object.keys(formik.values).reduce((acc, field) => ({ ...acc, [field]: true }), {})
                      ); // Mark all fields as touched
                    }
                  }}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div className="drawer_main_div">
          {itemLoaded===false && title==='Edit Facility' ?
        
        <div className="row h-100 d-flex jusitfy-content-center align-items-center">
              <Spin />
        </div>
        :
            <div className="bg-white mx-3">
              <Tabs defaultActiveKey="1" 
              items={items}
              activeKey={selectedTab}
              onChange={(key) => setSelectedTab(key)}
                />
            </div>
}
        </div>
      </Drawer>
      <SaveConfirmationDialog
        loading={loading?.remove}
        open={open}
        onClose={setOpen}
        onSave={formik.handleSubmit}
        title="Save"
        content="Are you sure you want to Save this?"
        onCancelLabel="Cancel"
        onConfirmLabel="Save"
      />
    </>
  );
}

export default FacilityDrawer;
