import React from "react";
import { DoctorCard } from "../facility/components/common/doctor.card";
import { Image } from "antd";
import NoData from "components/no-data/NoData";
const RequestInfoModal = ({ info }) => {
  const {
    firstName = "",
    lastName = "",
    phone = "",
    email = "",
    speciality = null,
    treatment = null,
    doctor = null,
    facility = null,
    media = [],
  } = info;

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            {/****** Basic Info *****/}
            <div className="card mb-3 mb-lg-5">
              <div className="card-header card-header-content-between">
                <h5>Basic Info</h5>
              </div>
              <div className="card-body">
                <div className="card-body">
                  <div className="row mb-4">
                    <label
                      for="departmentLabel"
                      className="col-sm-3 col-form-label form-label"
                    >
                      First Name
                    </label>

                    <div className="col-sm-9">
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={firstName}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label
                      for="departmentLabel"
                      className="col-sm-3 col-form-label form-label"
                    >
                      Last Name
                    </label>

                    <div className="col-sm-9">
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={lastName && lastName !== "null" ? lastName : ''}
                        />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label
                      for="departmentLabel"
                      className="col-sm-3 col-form-label form-label"
                    >
                      Email
                    </label>

                    <div className="col-sm-9">
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={email}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label
                      for="departmentLabel"
                      className="col-sm-3 col-form-label form-label"
                    >
                      Phone
                    </label>

                    <div className="col-sm-9">
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={phone}
                      />
                    </div>
                  </div>
  

                  <div className="list-group-item mb-4">
                    <div className="row align-items-center">
                      <div className="col">
                        <span>Speciality </span>
                      </div>

                      <div className="col-auto">
                        <a
                          className="badge bg-soft-primary text-primary p-2"
                          href="/"
                        >
                          {speciality?.name_en}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col">
                        <span>Treatment </span>
                      </div>

                      <div className="col-auto">
                        <a
                          className="badge bg-soft-primary text-primary p-2"
                          href="/"
                        >
                          {treatment?.name_en}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
            {/****** Images *****/}
            <div className="card mb-3 mb-lg-5">
              <div className="card-header card-header-content-between">
                <h5>Images</h5>
              </div>

              <div className="card-body">
                <div
                  id="fancyboxGallery"
                  className="js-fancybox row justify-content-sm-center gx-3"
                >
                  {media.length ? (
                    media?.map(({ url = "" }) => (
                      <div className="col-6 col-sm-4 col-md-3 mb-3 mb-lg-5">
                        <div className="card card-sm">
                          <Image
                            className="card-img-top"
                            src={url}
                            alt="Image Description"
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            {/******  doctor *****/}
            <div className="card mb-3 mb-lg-5 ">
              <div className="card-header card-header-content-between">
                <h5>Doctor</h5>
              </div>

              <div className="card-body">
                {doctor ? <DoctorCard {...doctor} /> : <NoData />}
              </div>
            </div>
            {/******  Facility  *****/}
            <div className="card mb-3 mb-lg-5 ">
              <div className="card-header card-header-content-between">
                <h5>Facility</h5>
              </div>

              <div className="card-body">
                {facility ? (
                  <>
                    <div className="row mb-4">
                      <label
                        for="departmentLabel"
                        className="col-sm-3 col-form-label form-label"
                      >
                        Name
                      </label>

                      <div className="col-sm-9">
                        <input
                          disabled={true}
                          type="text"
                          className="form-control"
                          value={facility?.name_en}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label
                        for="departmentLabel"
                        className="col-sm-3 col-form-label form-label"
                      >
                        Email
                      </label>

                      <div className="col-sm-9">
                        <input
                          disabled={true}
                          type="text"
                          className="form-control"
                          value={facility?.email}
                        />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label
                        for="departmentLabel"
                        className="col-sm-3 col-form-label form-label"
                      >
                        Number
                      </label>

                      <div className="col-sm-9">
                        <input
                          disabled={true}
                          type="text"
                          className="form-control"
                          value={facility?.phone}
                        />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label
                        for="departmentLabel"
                        className="col-sm-3 col-form-label form-label"
                      >
                        Address
                      </label>

                      <div className="col-sm-9">
                        <input
                          disabled={true}
                          type="text"
                          className="form-control"
                          value={facility?.address_en}
                        />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label
                        for="departmentLabel"
                        className="col-sm-3 col-form-label form-label"
                      >
                        Zip Code
                      </label>

                      <div className="col-sm-9">
                        <input
                          disabled={true}
                          type="text"
                          className="form-control"
                          value={facility?.zipCode}
                        />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label
                        for="departmentLabel"
                        className="col-sm-3 col-form-label form-label"
                      >
                        Country
                      </label>

                      <div className="col-sm-9">
                        <input
                          disabled={true}
                          type="text"
                          className="form-control"
                          value={facility?.city?.country?.name_en}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label
                        for="departmentLabel"
                        className="col-sm-3 col-form-label form-label"
                      >
                        City
                      </label>

                      <div className="col-sm-9">
                        <input
                          disabled={true}
                          type="text"
                          className="form-control"
                          value={facility?.city?.name_en}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestInfoModal;
