import { useState } from "react";
import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { Spin } from "antd";

export const DeleteConfirmationDialogWithComment = ({
  loading = false,
  open,
  onClose,
  onDelete,
  title,
  content,
  onCancelLabel,
  onConfirmLabel,
}) => {
  // State to hold the comment
  const [comment, setComment] = useState('');

  // Handle comment change
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  // Handle delete action, passing the comment to the parent function
  const handleDeleteWithComment = () => {
    onDelete(comment); 
    setComment('');
  };

  return (
    <Dialog
      open={open ? true : false}
      PaperProps={{ style: { borderRadius: 17, width: "400px" } }}
    >
      <Card className="card pb-2" style={{ width: "400px" }}>
        <div
          className="pt-3 pb-1 border-bottom border-1 text-center"
          style={{ backgroundColor: "white" }}
        >
          <label className="h3 text-grey text-center">{title}</label>
        </div>
        <div className="card-body overflow-auto text-center">
          <label className="text_16_500">{content}</label>
        </div>

        {/* Add the textarea for comment input */}
        <div className="card-body overflow-auto text-center">
          <textarea
            className="form-control"
            placeholder="Enter your reason for deleting..."
            rows="4"
            value={comment}
            onChange={handleCommentChange}
            required
          />
        </div>

        <div className="d-flex justify-content-center mb-2">
          <button
            onClick={() => onClose(false)}
            className="btn bg-transparent py-2 margin_right_10 border p-5"
          >
            {onCancelLabel}
          </button>
          <button
            disabled={loading || comment.trim() === ""}  // Disable if loading or comment is empty
            style={{ background: "#f30301" }}
            onClick={handleDeleteWithComment}  // Use the new delete handler
            className="btn py-2 text-white p-5"
          >
            {loading ? <Spin /> : onConfirmLabel}
          </button>
        </div>
      </Card>
    </Dialog>
  );
};
