// Inner nested table 
import React from 'react';
import { Table, Image } from 'antd';
import {
  DeleteButton,
  EditButton,
} from "components/button/Buttons";
import { FindTimeAgo,FindCountryCode } from "utils/helpers/helper-functions.js";
import { FALLBACK_IMAGE_URL } from "utils/constants/contants";

const InnerTable = ({
  loading,
  records,
  columns,
  rowClassName,
  activeTab,
  handleCommentTabClick,
  handleRequestPaymentTabClick,
  openAddCommentDrawer,
  openAddPaymentDrawer,
  setEditComment,
  setCommentModal,
  setDeleteComment,
  commentsData,
  paymentsData,
  expandedRowKey,
  onExpand,
}) => (
  <Table
    className="components-table-demo-nested table_tag"
    size="small"
    scroll={{ x: "calc(100vw - 420px)" }}
    loading={loading?.getAll || false}
    pagination={false}
    dataSource={records}
    rowClassName={rowClassName}
    columns={columns}
    layout="fixed"
    expandIconColumnIndex={-1}
    expandedRowKeys={expandedRowKey ? [expandedRowKey] : []} // Only expand one row at a time
    onExpand={onExpand}
    expandable={{
      expandedRowRender: (records) => (
        <>
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-4 ">
                {/****** Basic Info *****/}
                <div className="card mb-3 mb-lg-5">
                  <div className="card-header card-header-content-between">
                    <h5>
                      User{" "}
                      <span className="ml-1" style={{ color: "blue" }}>
                        {records?.uniqueId}
                      </span>
                    </h5>
                  </div>
                  <div className="card-body">
                    <table className="my-custom-table">
                      <thead>
                        <tr>
                          <th>User Name</th>
                          <td>
                            {records.firstName}{" "}
                            {records.lastName &&
                            records.lastName !== "null"
                              ? records.lastName
                              : ""}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Country</th>
                          <td>
                            <>
                              <Image
                                className="mb-1 "
                                width={15}
                                preview={false}
                                src={
                                  FindCountryCode(records?.country)
                                    ?.code
                                    ? `assets_modules/flag-icon-css/flags/1x1/${FindCountryCode(
                                        records?.country
                                      ).code.toLowerCase()}.svg`
                                    : FALLBACK_IMAGE_URL // Fallback if code is undefined
                                }
                                fallback={FALLBACK_IMAGE_URL}
                              />
                            </>
                            <span className="mx-2">
                              {records.country}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Phone</th>
                          <td>{records.phone ? records.phone : "-"}</td>
                        </tr>
                        <tr>
                          <th>Email</th>
                          <td>{records.email ? records.email : "-"}</td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          <td>
                            {records.status ? records.status : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>IP</th>
                          <td>{records.ip ? records.ip : ""}</td>
                        </tr>
                        <tr>
                          <th>Browser</th>
                          <td>
                            {records.browser ? records.browser : ""}
                          </td>
                        </tr>
                        <tr>
                          <th>Device</th>
                          <td>
                            {records.device ? records.device : ""}
                          </td>
                        </tr>
                        <tr>
                          <th>Page URL</th>
                          <td>
                            {records.pageUrl ? records.pageUrl : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id={`timeline-tab-${records.id}`}
                      data-bs-toggle="tab"
                      data-bs-target={`#timeline-${records.id}`}
                      type="button"
                      role="tab"
                      aria-controls="timeline"
                      aria-selected="false"
                    >
                      Timeline
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link "
                      id={`comment-tab-${records.id}`}
                      data-bs-toggle="tab"
                      data-bs-target={`#comment-${records.id}`}
                      type="button"
                      role="tab"
                      aria-controls="comment"
                      aria-selected="false"
                      onClick={() => handleCommentTabClick(records.id)}
                    >
                      Comment
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id={`payment-tab-${records.id}`}
                      data-bs-toggle="tab"
                      data-bs-target={`#payment-${records.id}`}
                      type="button"
                      role="tab"
                      aria-controls="payment"
                      aria-selected="false"
                      onClick={() =>
                        handleRequestPaymentTabClick(records.id)
                      }
                    >
                      Payment
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id={`reason-tab-${records.id}`}
                      data-bs-toggle="tab"
                      data-bs-target={`#reason-${records.id}`}
                      type="button"
                      role="tab"
                      aria-controls="reason"
                      aria-selected="false"
                    >
                      Client Request
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id={`timeline-${records.id}`}
                    role="tabpanel"
                    aria-labelledby={`timeline-tab-${records.id}`}
                  >
                    <div className="row mt-5">
                      <div className="col-lg-12">
                        <div className="card mb-3 mb-lg-5 ">
                          <div className="card-header card-header-content-between">
                            <h5>Request Logs</h5>
                          </div>

                          <div className="card-body my-custom-table-container">
                            <table className="my-custom-table">
                              <thead>
                                <tr>
                                  <th>Created at</th>
                                  <th>User</th>
                                  <th>Message</th>
                                </tr>
                              </thead>
                              <tbody>
                                {records?.requestLog?.map(
                                  (logRecord, index) => (
                                    <tr key={index}>
                                      <td>
                                        {FindTimeAgo(
                                          logRecord?.createdAt
                                        )}
                                      </td>
                                      <td>{logRecord?.user?.name}</td>
                                      <td>{logRecord.message}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${
                      activeTab === `comment-${records.id}`
                        ? "show active"
                        : ""
                    }`}
                    id={`comment-${records.id}`}
                    role="tabpanel"
                    aria-labelledby={`comment-tab-${records.id}`}
                  >
                    <div className="row mt-5">
                      <div className="col-lg-12-">
                        {/****** Basic Info *****/}
                        <div className="card mb-3 mb-lg-5">
                          <div className="card-header card-header-content-between">
                            <h5>Comment</h5>
                            <h3
                              role="button"
                              onClick={(e) =>
                                openAddCommentDrawer(e, records?.id)
                              }
                              title="Add Comment"
                            >
                              {" "}
                              +
                            </h3>
                          </div>
                          <div className="card-body">
                            <table className="my-custom-table">
                              <thead>
                                <tr>
                                  <th>Time</th>
                                  <th>Author</th>
                                  <th>Text</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {commentsData?.map(
                                  (commentRecord, index) => (
                                    <tr key={index}>
                                      <td>
                                        {FindTimeAgo(
                                          commentRecord?.createdAt
                                        )}
                                      </td>
                                      <td>
                                        {commentRecord?.user?.name}
                                      </td>
                                      <td>{commentRecord.text}</td>
                                      <td>
                                        <div className="d-flex mx-auto ">
                                          <span className="nav-link mx-1">
                                            <EditButton
                                              onClick={() => {
                                                setEditComment(
                                                  commentRecord
                                                );
                                                setCommentModal(true);
                                              }}
                                            />
                                          </span>
                                          <span className="nav-link ">
                                            <DeleteButton
                                              onClick={() =>
                                                setDeleteComment(
                                                  commentRecord
                                                )
                                              }
                                            />
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      activeTab === `payment-${records.id}`
                        ? "show active"
                        : ""
                    }`}
                    id={`payment-${records.id}`}
                    role="tabpanel"
                    aria-labelledby={`payment-tab-${records.id}`}
                  >
                    <div className="row mt-5">
                      <div className="col-lg-12-">
                        {/****** Basic Info *****/}
                        <div className="card mb-3 mb-lg-5">
                          <div className="card-header card-header-content-between">
                            <h5>Payment</h5>
                            <h3
                              role="button"
                              onClick={(e) =>
                                openAddPaymentDrawer(e, records?.id)
                              }
                              title="Add Payment"
                            >
                              {" "}
                              +
                            </h3>
                          </div>
                          <div className="card-body">
                            <table className="my-custom-table">
                              <thead>
                                <tr>
                                  <th>Time</th>
                                  <th>Author</th>
                                  <th>Country</th>
                                  <th>Treatment</th>
                                  <th>Amount</th>
                                  <th>Comment</th>
                                </tr>
                              </thead>
                              <tbody>
                                {paymentsData?.map(
                                  (paymentRecord, index) => (
                                    <tr key={index}>
                                      <td>
                                        {FindTimeAgo(
                                          paymentRecord?.createdAt
                                        )}
                                      </td>
                                      <td>
                                        {paymentRecord?.user?.name}
                                      </td>
                                      <td>
                                        {
                                          paymentRecord?.country
                                            ?.name_en
                                        }
                                      </td>
                                      <td>
                                        {
                                          paymentRecord?.treatment
                                            ?.name_en
                                        }
                                      </td>
                                      <td>{paymentRecord.amount}</td>
                                      <td>
                                        {paymentRecord.description}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id={`reason-${records.id}`}
                    role="tabpanel"
                    aria-labelledby={`reason-tab-${records.id}`}
                  >
                    <div className="row mt-5">
                      <div className="col-lg-12">
                        <div className="card mb-3 mb-lg-5 ">
                          <div className="card-header card-header-content-between">
                            <h5>Client Request</h5>
                          </div>

                          <div className="card-body my-custom-table-container">
                            <table className="my-custom-table">
                              <thead>
                                <tr>
                                  <th>Client Request</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr key={1}>
                                  <td>{records?.message}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
      rowExpandable: (records) => records.id !== "Not Expandable",
    }}
    expandRowByClick
    bordered
  />
);

export default InnerTable;
