import { notification } from "antd";
import { CRUD_COMMON_MESSAGE } from "utils/constants/contants";
import * as requestFromServer from "./Crud";
import { requestSlice } from "./Reducer";
const { actions } = requestSlice;

export const loading = (payload) => (dispatch) => {
  dispatch(actions.setLoading(payload));
};

export const getAll = (pagination,refresh) => (dispatch) => {

  refresh && dispatch(actions.setLoading({ getAll: true }));
  return requestFromServer
    .getAll(pagination)
    .then((response) => {
      const { records = [], count = 0 } = response.data;
      dispatch(actions.getAll({ records, count }));
    })
    .catch((error) => {})
    .finally(() => {
      dispatch(actions.setLoading({ getAll: false }));
    });
};

export const create =
  (data, setDrawer, resetForm, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ create: true }));
    return requestFromServer
      .create(data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
          dispatch(getAll(pagination));
          setDrawer(false);
          resetForm();
        }
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(actions.setLoading({ create: false }));
      });
  };
  export const createEvent =
  (data, setEvent, resetForm, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ create: true }));
    return requestFromServer
      .createEvent(data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
          setEvent("done");
          resetForm();
        }
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(actions.setLoading({ create: false }));
      });
  };

  export const createEventCompleted =
  (id,data, setEventCompleted, resetForm, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ create: true }));
    return requestFromServer
      .createEventCompleted(id,data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
          setEventCompleted("done");
          resetForm();
        }
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(actions.setLoading({ create: false }));
      });
  };


export const remove = (id, setDeleteRecord, pagination) => (dispatch) => {
  dispatch(actions.setLoading({ remove: true }));
  return requestFromServer
    .remove(id)
    .then(({ data }) => {
      if (data) {
        notification.success({ description: CRUD_COMMON_MESSAGE.REMOVE });
        dispatch(getAll(pagination));
        setDeleteRecord(null);
      }
    })
    .catch((error) => {})
    .finally(() => {
      dispatch(actions.setLoading({ remove: false }));
    });
};


export const removeComment = (id, setDeleteComment, requestId) => (dispatch) => {
  dispatch(actions.setLoading({ remove: true }));
  
  return requestFromServer
    .removeComment(id)
    .then(({ data }) => {
      if (data) {
        dispatch(getRequestComment({ query: `where[requestId]=${requestId}` }));
        setDeleteComment(null);
        notification.success({ description: CRUD_COMMON_MESSAGE.REMOVE });
      }
    })
    .catch((error) => {})
    .finally(() => {
      dispatch(actions.setLoading({ remove: false }));
    });
};


export const updateComment = (id, data,setCommentModal) => (dispatch) => {
  dispatch(actions.setLoading({ update: true }));
  const requestId=data.requestId
  return requestFromServer
      .updateComment(id, data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.UPDATE });
          dispatch(getRequestComment({ query: `where[requestId]=${requestId}` }));
          setCommentModal(false);
        }

      })
      .catch((error) => {
        dispatch(actions.setLoading({ update: false }));

      })
  };


// export const update =
//   (id, data, setDrawer, resetForm, pagination) => (dispatch) => {
//     dispatch(actions.setLoading({ update: true }));
//     return requestFromServer
//       .update(id, data)
//       .then(({ data }) => {
//         if (data) {
//           notification.success({ description: CRUD_COMMON_MESSAGE.UPDATE });
//           dispatch(getAll(pagination));
//           setDrawer(false);
//           resetForm();
//         }
//       })
//       .catch((error) => {})
//       .finally(() => {
//         dispatch(actions.setLoading({ update: false }));
//       });
//   };

  

export const update =
  (id, data,setUpdateModal, pagination) => (dispatch) => {
    return requestFromServer
      .update(id, data)
      .then(({ data }) => {
        if (data) {
          setUpdateModal("done")
        }

      })
      .catch((error) => {})
  };

  

  export const comment =
  (data, setDrawer, resetForm,flag) => (dispatch) => {
    dispatch(actions.setLoading({ create: true }));
    return requestFromServer
      .comment(data)
      .then(({ data }) => {
        if (data) {
          flag && notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
          dispatch(getRequestComment({ query: `where[requestId]=${data.requestId}` }));
          setDrawer(false);
          resetForm();
        }
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(actions.setLoading({ create: false }));
      });
  };

  export const payment =
  (data, setDrawer, resetForm, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ create: true }));
    return requestFromServer
      .payment(data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
          dispatch(getRequestPayment({ query: `where[requestId]=${data.requestId}` }));
          setDrawer(false);
          resetForm();
        }
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(actions.setLoading({ create: false }));
      });
  };

  
export const getRequestPayment = (pagination) => (dispatch) => {

  dispatch(actions.setLoading({ getAll: true }));
  return requestFromServer
    .getRequestPayment(pagination)
    .then((response) => {
      const { records = [] } = response.data;
      dispatch(actions.setPaymentsData(records));

    })
    .catch((error) => {})
    .finally(() => {
      dispatch(actions.setLoading({ getAll: false }));
    });
};

export const getRequestComment = (pagination) => (dispatch) => {

  dispatch(actions.setLoading({ getAll: true }));
  return requestFromServer
    .getRequestComment(pagination)
    .then((response) => {
      const { records = [] } = response.data;
      dispatch(actions.setCommentsData(records));

    })
    .catch((error) => {})
    .finally(() => {
      dispatch(actions.setLoading({ getAll: false }));
    });
};