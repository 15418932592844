import {  Pagination, Table } from "antd";
import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  DeleteButton,
  EditButton,
  ViewButton,
} from "components/button/Buttons";
import { DeleteConfirmationDialog } from "components/delete-confirmation-dialog/DeleteConfirmationDialog";
import * as TeamAction from "redux/team/Actions";

function TeamsTable(props) {
  const {
    remove,
    setRemove,
    setEdit,
    setView,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    setDrawer,
  } = props;

  const dispatch = useDispatch();
  const pagination = getPagination();

  const { records, count, loading } = useSelector(
    (state) => ({
      records: state.team?.records || [],
      count: state.team?.count || 0,
      loading: state.team?.loading,
    }),
    shallowEqual
  );
  const fetchTeam = useCallback(() => {
    dispatch(TeamAction.getAll(pagination));
  }, 
  // eslint-disable-next-line
  [pageSize, pageNumber, searchText, dispatch]);

  useEffect(() => {
    fetchTeam();
  },
  // eslint-disable-next-line
  [dispatch, pageSize, pageNumber, searchText]);

  const removeHandler = () => {
    dispatch(TeamAction.remove(remove?.id, setRemove, pagination));
  };

  const columns = [
    {
      title: (
        <>
          First Name <span className="form-label-secondary">(English)</span>
        </>
      ),
      dataIndex: "firstName_en",
      key: "firstName_en",
    },
    {
      title: (
        <>
         Last Name <span className="form-label-secondary">(English)</span>
        </>
      ),
      dataIndex: "lastName_en",
      key: "lastName_en",
    },
    {
      title: (
        <>
          Email <span className="form-label-secondary">(English)</span>{" "}
        </>
      ),
      dataIndex: "email",
      key: "email",
    },
    {
      title: (
        <>
          Designation <span className="form-label-secondary">(English)</span>
        </>
      ),

      dataIndex: "designation_en",
      key: "designation_en",
    },
    {
      title: "Is Leader",
      dataIndex: "isLeader",
      key: "isLeader",
      render: (isLeader, entity) => {
        return (
          <>
            <div
              className={
                entity.isLeader
                  ? "badge bg-soft-success text-success"
                  : "badge bg-soft-danger text-danger"
              }
            >
              <span
                className={
                  entity.isLeader
                    ? "legend-indicator bg-success"
                    : "legend-indicator bg-danger"
                }
              ></span>
              {entity.isLeader ? "Leader" : "Not Leader"}
            </div>{" "}
          </>
        );
      },
    },
    
    {
      title: "Is Featured",
      dataIndex: "isFeatured",
      key: "isFeatured",
      render: (isFeatured, entity) => {
        return (
          <>
            <div
              className={
                entity.isFeatured
                  ? "badge bg-soft-success text-success"
                  : "badge bg-soft-danger text-danger"
              }
            >
              <span
                className={
                  entity.isFeatured
                    ? "legend-indicator bg-success"
                    : "legend-indicator bg-danger"
                }
              ></span>
              {entity.isFeatured ? "Featured" : "Not Featured"}
            </div>{" "}
          </>
        );
      },
    },
    {
      title: "Is Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive, entity) => {
        return (
          <>
            <div
              className={
                entity.isActive
                  ? "badge bg-soft-success text-success"
                  : "badge bg-soft-danger text-danger"
              }
            >
              <span
                className={
                  entity.isActive
                    ? "legend-indicator bg-success"
                    : "legend-indicator bg-danger"
                }
              ></span>
              {entity.isActive ? "Active" : "Not Active"}
            </div>{" "}
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "name_de",
      key: "name_de",
      width: "150px",
      render: (name_en, entity) => {
        return (
          <>
            <div className="d-flex mx-auto ">
              <span className="nav-link">
                <ViewButton
                  onClick={() => {
                    setView(entity);
                    setDrawer(true);
                  }}
                />
              </span>

              <span className="nav-link mx-1">
                <EditButton
                  onClick={() => {
                    setEdit(entity);
                    setDrawer(true);
                  }}
                />
              </span>
              <span className="nav-link ">
                <DeleteButton onClick={() => setRemove(entity)} />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  const paginationHandler = (current, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(current);
  };

  return (
    <>
      <div className="pb-2 pt-3 table_main_div " style={{ height: "contain" }}>
        <div className="row " style={{ paddingRight: "50px" }}>
          <div className="d-grid d-sm-flex gap-3 justify-content-between">
            <div className=" mx-4 ">
              <h3 className="  pt-3">Total - {count}</h3>
            </div>
            <div
              className="input-group input-group-merge input-group-flush mr-4 ml-2 "
              style={{ width: "180px" }}
            >
              <div className="input-group-prepend input-group-text">
                <i className="bi-search"></i>
              </div>
              <input
                onChange={(e) => setSearchText(e.target.value)}
                id="datatableSearch"
                type="search"
                className="form-control"
                placeholder="Search "
                aria-label="Search "
              />
            </div>
            {/* <!-- End Search --> */}
          </div>
        </div>

        <div className="domain_history table_main py-3 px-2">
          <Table
            className="table_tag"
            size="small"
            loading={loading?.getAll || false}
            // scroll={{ y: "calc(100vh - 420px)" }}
            scroll={{ x: "calc(100wh - 420px)" }}

            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  // navigate(`/countries/${record?.id}`);
                  // navigateTotrafficPage(record, event);
                },
              };
            }}
            pagination={false}
            dataSource={records}
            columns={columns}
          />
          <div
            className="d-flex mt-3 mr-5 justify-content-end"
            style={{ marginRight: "20px" }}
          >
            <Pagination
              showSizeChanger
              onChange={paginationHandler}
              onShowSizeChange={paginationHandler}
              pageSize={pageSize}
              defaultCurrent={1}
              total={count}
            />
          </div>
        </div>
      </div>

      <DeleteConfirmationDialog
        loading={loading?.remove}
        open={remove}
        onClose={setRemove}
        onDelete={removeHandler}
        title="Delete"
        content="Are you sure you want to delete it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Delete"
      />
    </>
  );
}

export default TeamsTable;
