import { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as actions from "redux/request/Actions";
import { useDispatch } from "react-redux";

const EventModal = ({ ...props }) => {
  const user = useSelector((state) => state?.auth?.user);
  const { editRecord } = props;
  const {
    date = null,
    time = null,
    priority = "",
    status = "",
    message = null,
    requestId = props?.event?.id || null,
    userId=user?.id
  } = editRecord || {};

  const dispatch = useDispatch();
  const isLoading = false;
   // Get today's date in YYYY-MM-DD format
   const today = new Date().toISOString().split("T")[0];
   // Get tomorrow's date in YYYY-MM-DD format
   const tomorrow = new Date();
   tomorrow.setDate(tomorrow.getDate() + 1);
   const defaultDate = tomorrow.toISOString().split("T")[0];
   
   // Get current time in HH:MM format
   const now = new Date();
   const defaultTime = `${String(now.getHours()).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}`;
 
  const [minTime, setMinTime] = useState("");
  
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    formik.handleChange(e);
    if (selectedDate === today) {
      setMinTime(defaultTime);
    } else {
      setMinTime(""); // No time restriction for future dates
    }
  };
  
  const initialValues = {
    date: date || defaultDate,
    time: time || defaultTime,
    priority: priority || "low",
    status: status ||  "new-lead",
    message: message || "",
    requestId:requestId || "",
    userId:userId||""
  };

  const validationSchema = Yup.object().shape({
    date: Yup.string().required("Date is required"),
    time: Yup.string().required("Time is required"),
    priority: Yup.string().required("Priority is required"),
    status: Yup.string().required("Event Status is required"),
    message: Yup.string().required("Message is required"),
  });
  const resetForm = () => {
    formik.resetForm();
  };

  const onSubmit = async (values) => {
    dispatch(actions.loading({ create: true }));
   
    const localDateTime = new Date(`${formik.values.date}T${formik.values.time}`);
    const utcDate = localDateTime.toISOString().split('T')[0]; // UTC date
    const utcTime = localDateTime.toISOString().split('T')[1].slice(0, 5); // UTC time
    const payload = {
    ...values,
    date: utcDate,
    time: utcTime
    };
    const action = actions.createEvent(
      payload,
      props.setEvent,
      resetForm,
      props.pagination
    );
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  useEffect(() => {
    if (formik.values.date === today) {
      setMinTime(defaultTime);
    } else {
      setMinTime("");
    }
  }, [formik.values.date, defaultTime, today]);

  
  
  return (
    <div className="container-fluid ">
      <h1 className="text-center t-2 ">Reminder</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <h6>User: {user.name}</h6>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3 col-sm-12">
            <h6>Date</h6>
            <input
              type="date"
              className="form-control"
              value={formik.values.date}
              name="date"
              // onChange={formik.handleChange}
              onChange={handleDateChange}
              onBlur={formik.handleBlur}
              min={today} 
            />
          </div>

          <div className="col-md-3 col-sm-12">
            <h6>Time</h6>
            <input
              type="time"
              name="time"
              className="form-control"
              value={formik.values.time}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              min={minTime}
            />
          </div>

          <div className="col-md-3 col-sm-12">
            <h6>Priority</h6>
            <select
              className="form-control"
              value={formik.values.priority}
              name="priority"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="low">Low</option>
              <option value="normal">Normal</option>
              <option value="high">High</option>
            </select>
          </div>

          <div className="col-md-3 col-sm-12">
            <h6>Event Status</h6>
            <select
              className="form-control"
              value={formik.values.status}
              name="status"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="new-lead">New Lead</option>
              <option value="no-answer">No Answer</option>
              <option value="duplicate-task">Duplicate Task</option>
              <option value="not-interested">Not Interested</option>
              <option value="Follow-up-call-back">Follow-Up Call Back</option>
              <option value="wrong-number">Wrong Number</option>
              <option value="scheduled">Scheduled</option>
              <option value="confirmed">Confirmed</option>
              <option value="cancelled">Cancelled</option>
              <option value="rescheduled">Rescheduled</option>
              <option value="pending">Pending</option>
              <option value="in-progress">In Progress</option>
              <option value="arrived">Arrived</option>
              <option value="pending-contact">Pending Contact</option>
              <option value="patient-confirmed">Patient Confirmed</option>
              <option value="patient-not-reachable">
                Patient Not Reachable
              </option>
            </select>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            <h6>Message</h6>
            <textarea
              placeholder=""
              type="text"
              name="message"
              rows="4"
              className={`form-control w-100 drawer_input ${
                formik.errors.message && formik.touched.message
                  ? "input_error"
                  : ""
              }`}
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
          </div>
        </div>
        <div className="text-end-or-center mt-4">
          <button
            type="submit"
            disabled={isLoading}
            className={`btn-sm btn ${
              isLoading ? "btn-light" : "btn-theme"
            } px-4`}
          >
            {isLoading ? <Spin /> : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EventModal;
