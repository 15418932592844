import Blogs from "app/dashboard/blog/blog.js";
import Testimonials from "app/dashboard/testimonials/testimonials";
import Cities from "app/dashboard/city/city";
import ServiceLanguage from "app/dashboard/common/service-language/service-language";
import ServicePrice from "app/dashboard/common/service-price/service-price";
import Service from "app/dashboard/common/service/service";
import Countries from "app/dashboard/country/country";
import Dashboard from "app/dashboard/dashboard";
import DashboardHome from "app/dashboard/dashboard-home/dashboard-home";
import Profile from "app/dashboard/dashboard-profile/profile";
import Doctors from "app/dashboard/doctor/doctor";
import Facilities from "app/dashboard/facility/facility";
import Requests from "app/dashboard/request/request";
import Specialities from "app/dashboard/speciality/speciality";
import Treatments from "app/dashboard/treatment/treatment";
import Diseases from "app/dashboard/diseases/diseases";
import Users from "app/dashboard/user/user";
import Teams from "app/dashboard/teams/teams";
import NotFoundError from "app/error-pages/not-found-error";
import ForgetPassword from "app/forget-password/forget-password";
import Login from "app/login/login";
import { createBrowserRouter } from "react-router-dom";
import { AdminValidationRoute } from "./admin-validation.route";
import { AdminEditorAuthorValidationRoute } from "./AdminEditorAuthor-validation.route";
import { IsLoginValidation } from "./validation.route ";
import Settings from "app/dashboard/settings/settings"
import Content from "app/dashboard/add-content/add-content"
import FilterContent from "app/dashboard/add-content/separate-filters-content/filter-content"
import DraftContent from "app/dashboard/add-content/draft-content/draft-content"


export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <NotFoundError />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <NotFoundError />,
  },
  {
    path: "/forget_password",
    element: <ForgetPassword />,
    errorElement: <NotFoundError />,
  },
  {
    path: "/countries",
    element: (
      <IsLoginValidation>
        <AdminValidationRoute>
          <Dashboard />
        </AdminValidationRoute>
      </IsLoginValidation>
    ),
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <Countries />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
        breadcrumbName: "countries",
      },
      {
        path: ":id",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <Cities />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
        breadcrumbName: "/cities",
      },
    ],
  },
  {
    path: "/treatments",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <Treatments />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
      },
    ],
  },
  {
    path: "/disease",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <Diseases />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
      },
    ],
  },
  {
    path: "/specialities",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <Specialities />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
      },
    ],
  },
  {
    path: "/service-languages",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <ServiceLanguage />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
      },
    ],
  },

  {
    path: "/service-price",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <ServicePrice />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
      },
    ],
  },

  {
    path: "/service",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <Service />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
      },
    ],
  },
  {
    path: "/doctors",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <Doctors />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
      },
    ],
  },
  {
    path: "/facilities",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <Facilities />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
      },
    ],
  },
  {
    path: "/requests",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <Requests />
          </IsLoginValidation>
        ),
      },
    ],
  },
  {
    path: "/blogs",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <Blogs />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
      },
    ],
  },
  {
    path: "/testimonials",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <Testimonials />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
      },
    ],
  },

  {
    path: "/dashboard",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <DashboardHome />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
      },
    ],
  },
  {
    path: "/team",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <Teams />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
      },
    ],
  },
  {
    path: "/users",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <Users />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
      },
    ],
  },
  {
    path: "/profile",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <Profile />
          </IsLoginValidation>
        ),
      },
    ],
  },
 
  {
    path: "/settings",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
            <AdminValidationRoute>
              <Settings />
            </AdminValidationRoute>
          </IsLoginValidation>
        ),
      },
    ],
  },
  {
    path: "/content",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
          <AdminEditorAuthorValidationRoute>
            <Content />
          </AdminEditorAuthorValidationRoute>
        </IsLoginValidation>
        ),
      },
    ],
  }
  ,
  {
    path: "/content-filters",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
          <AdminEditorAuthorValidationRoute>
            <FilterContent/>
          </AdminEditorAuthorValidationRoute>
        </IsLoginValidation>
        ),
      },
    ],
  },
  {
    path: "/draft-content",
    element: <Dashboard />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "",
        element: (
          <IsLoginValidation>
          <AdminEditorAuthorValidationRoute>
            <DraftContent />
          </AdminEditorAuthorValidationRoute>
        </IsLoginValidation>
        ),
      },
    ],
  }


  
]);
