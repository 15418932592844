import { Drawer, Space, Spin } from "antd";
import ErrorMessage from "components/error-message/ErrorMessage";
import { useFormik } from "formik";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/common/why-choose-us/Actions";
import * as Yup from "yup";

function WhyChooseUsDrawer(props) {
  const {
    setDrawer,
    drawer,
    getPagination,
    editRecord,
    setEditRecord,
    view,
    setView,
    onEdit,
    onCreate,
    recordId
  } = props;

  const { loading } = useSelector(
    (state) => ({
      loading: state.speciality?.loading,
    }),
    shallowEqual
  );

  const {
    text_en = "",
    text_ar = "",
    text_de = "",
    text_ru = "",
    text_uk = "",
    id = null,
  } = editRecord || {};

  const isEditing = Boolean(editRecord);
  const isLoading = isEditing ? loading.update : loading.create;

  const title = `${
    view ? "View" : isEditing ? " Edit " : " Add "
  } Why Choose Us`;
  const dispatch = useDispatch();
  const pagination = getPagination();

  const setEditDefaultValues = () => formik.setValues({ ...initialValues });

  const resetForm = () => formik.resetForm();

  const closeDrawer = (e) => {
  resetForm()
  setDrawer(false)
}

  const onDrawerChange = (e) => {
    if (!e) {
      view && setView(false);
      editRecord && setEditRecord(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  useEffect(() => {
    drawer && isEditing && setEditDefaultValues();
  }, 
  // eslint-disable-next-line
  [drawer]);

  //formik Handler
  const initialValues = {
    text_en: text_en || "",
    text_ar: text_ar || "",
    text_ru: text_ru || "",
    text_de: text_de || "",
    text_uk: text_uk || "",

  };

  const validationSchema = Yup.object().shape({
    text_en: Yup.string().required("Text (English) is required"),
    text_ar: Yup.string().notRequired(),
    text_ru: Yup.string().notRequired(),
    text_de: Yup.string().notRequired(),
    text_uk: Yup.string().notRequired(),

  });

  const onSubmit = async (values) => {
    dispatch(actions.loading(isEditing ? { update: true } : { create: true }));
    if (!isEditing) {
      values.facilityId = recordId || null;
    }
    const action = isEditing
      ? actions.update(id, values, setDrawer, resetForm, pagination)
      : actions.create(values, setDrawer, resetForm, pagination);
      

    const response = await dispatch(action);
    if (isEditing) {
      onEdit(values, id);
    } else {
      onCreate(response);
    }
  };
  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width="60%"
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!editRecord && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div className="drawer_main_div">
          <h2 className=" text-center">{title}</h2>
          <div className="container-fluid">
            <div className="row">
              <form onSubmit={formik.handleSubmit}>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span required">
                    Text
                    <span className="form-label-secondary"> (English)</span>
                  </span>
                  <textarea
                    disabled={view}
                    // disabled={isEditing ? true : false}
                    type="text"
                    name="text_en"
                    className={`form-control ${
                      formik.errors.text_en && formik.touched.text_en
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.text_en}
                    placeholder="Enter text in (English)"
                  />
                  {formik.errors.text_en && formik.touched.text_en && (
                    <ErrorMessage message={formik.errors.text_en} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Text <span className="form-label-secondary"> (Arabic)</span>
                  </span>
                  <textarea
                    disabled={view}
                    type="text"
                    name="text_ar"
                    className={`form-control ${
                      formik.errors.text_ar && formik.touched.text_ar
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.text_ar}
                    placeholder="Enter text in (Arabic)"
                  />
                  {formik.errors.text_ar && formik.touched.text_ar && (
                    <ErrorMessage message={formik.errors.text_ar} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Text
                    <span className="form-label-secondary"> (Russian)</span>
                  </span>
                  <textarea
                    disabled={view}
                    type="text"
                    name="text_ru"
                    className={`form-control ${
                      formik.errors.text_ru && formik.touched.text_ru
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.text_ru}
                    placeholder="Enter text in (Russian)"
                  />
                  {formik.errors.text_ru && formik.touched.text_ru && (
                    <ErrorMessage message={formik.errors.text_ru} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Text <span className="form-label-secondary"> (German)</span>
                  </span>
                  <textarea
                    disabled={view}
                    type="text"
                    name="text_de"
                    className={`form-control ${
                      formik.errors.text_de && formik.touched.text_de
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.text_de}
                    placeholder="Enter text in (German)"
                  />
                  {formik.errors.text_de && formik.touched.text_de && (
                    <ErrorMessage message={formik.errors.text_de} />
                  )}
                </div>
                   {/* /********** INPUT ********/}
                   <div className="my-2">
                  <span className="drawer_span">
                    Text <span className="form-label-secondary"> (Ukrainian)</span>
                  </span>
                  <textarea
                    disabled={view}
                    type="text"
                    name="text_uk"
                    className={`form-control ${
                      formik.errors.text_uk && formik.touched.text_uk
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.text_uk}
                    placeholder="Enter text in (Ukrainian)"
                  />
                  {formik.errors.text_uk && formik.touched.text_uk && (
                    <ErrorMessage message={formik.errors.text_uk} />
                  )}
                </div>
                {/* /********** BUTTON ********/}
              </form>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default WhyChooseUsDrawer;
