import { Drawer, Space, Spin } from "antd";
import { useFormik } from "formik";
import { useEffect,useState,useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/request/Actions";
import * as Yup from "yup";
import ErrorMessage from "components/error-message/ErrorMessage";
import * as treatmentActions from "redux/treatment/Actions";
import Select from "react-select";
import * as countryAction from "redux/country/Actions";

const RequestPaymentModal = ({ paymentModal, ...props }) => {
  const { setPaymentModal, getPagination, editRecord, setEditRecord } = props;
  const user = useSelector((state) => state.auth.user);
  const { loading,country,treatment } = useSelector(
    (state) => ({
      loading: state.request?.loading ,
      country: state.country?.records || [],
      treatment: state.treatment?.records || [],
       }),
    shallowEqual
  );
  const [treatment_ids, setTreatment_ids] = useState([]);
  const [country_ids, setCountry_ids] = useState([]);
  useEffect(
    () => {
      let query = `select[name_en]=true&select[id]=true&limit=1000000&offset=0`;
      dispatch(treatmentActions.getAll({ query:query }));
      // let query = `limit=1000000&offset=0`;
      dispatch(countryAction.getAll( "limit=1000000&offset=0"));

    },
    // eslint-disable-next-line
    []
  );

  //formik Handler
  const initialValues = {
    amount:  "",
    description:  "",
    // image:"",
    treatmentId:null,
    countryId:null,
    userId: user?.id || null,
    requestId:paymentModal || null
  };

  const validationSchema = Yup.object().shape({
    countryId: Yup.string().nullable().required("Country is Required"),
    treatmentId: Yup.string().nullable().required("Treatment is Required"),
    amount: Yup.string().required("Amount is Required"),
    userId: Yup.string(),
    requestId: Yup.string(),
    description: Yup.string().required("Description is Required"),

    });

  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
  };

  const view = false;
  const isEditing = Boolean(paymentModal);
  const isLoading = isEditing ? loading.update : loading.create;

  const title = "Add Payment";
  const dispatch = useDispatch();
  const pagination = getPagination();

  const closeDrawer = () => setPaymentModal(false);

  const onDrawerChange = (e) => {
    if (!e) {
      editRecord && setEditRecord(null);
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  const onSubmit = async (values) => {
    dispatch(actions.loading({ update: true }));
    await dispatch(actions.payment({ ...values }, setPaymentModal, pagination));
    formik.resetForm();
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  useEffect(
    () => {
      paymentModal && isEditing && setEditDefaultValues();
    },
    // eslint-disable-next-line
    [paymentModal]
  );

  const fetchSelectRecords = useCallback(() => {
    setTreatment_ids(
      treatment?.map(({ id: value, [`name_en`]: label }) => ({
        value,
        label: label ,
      })) || []
    );

  
    setCountry_ids(
      country?.map(({ id: value, [`name_en`]: label }) => ({
        value,
        label: label,
      })) || []
    );
  
    
  },
  [treatment,country]
);

  useEffect(
    () => {
      fetchSelectRecords();
    },
    [fetchSelectRecords]
  );
  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"70%"}
        onClose={closeDrawer}
        open={Boolean(paymentModal)}
        extra={
          <Space>
            {!view && (
              <>
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <>
          <div className="drawer_main_div">
            <h2 className=" text-center">{title}</h2>
            <div className="container-fluid">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                <div className="my-2">
                <span className="drawer_span  ">
                  Country Name <span className="form-label-secondary"></span>
                </span>
                <Select
                  isDisabled={view}
                  value={country_ids?.find( (option) => option.value === formik.values.countryId ) || null} 
                  options={country_ids}
                  className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                  classNamePrefix="select"
                  menuPlacement="bottom"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue("countryId", selectedOptions.value);
                  }}
                />
                {formik.errors.countryId && formik.touched.countryId && (
                  <ErrorMessage message={formik.errors.countryId} />
                )}
              </div>
                <div className="my-2">
                <span className="drawer_span  ">
                  Treatment Name <span className="form-label-secondary"></span>
                </span>
                <Select
                  isDisabled={view}
                  value={treatment_ids?.find((option) => option.value === formik.values.treatmentId) || null}
                  options={treatment_ids}
                  className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                  classNamePrefix="select"
                  menuPlacement="bottom"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue("treatmentId", selectedOptions.value);
                  }}
                />
                {formik.errors.treatmentId && formik.touched.treatmentId && (
                  <ErrorMessage message={formik.errors.treatmentId} />
                )}
              </div>

                  <div className="my-2">
                    <span className="drawer_span required">Amount</span>
                    <input
                          disabled={view}
                          type="number"
                          placeholder="Amount"
                          name="amount"
                          className={`form-control drawer_input ${
                            formik.errors.amount &&
                            formik.touched.amount
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.amount}
                        />
                        {formik.errors.amount &&
                          formik.touched.amount && (
                            <ErrorMessage
                              message={formik.errors.amount}
                            />
                          )}
                  </div>
                  <div className="my-2">
                    <span className="drawer_span required">Comment</span>
                    <textarea
                          disabled={view}
                          type="text"
                          placeholder="Comment..."
                          name="description"
                          rows={8}
                          className={`form-control drawer_input ${
                            formik.errors.description &&
                            formik.touched.description
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                        />
                        {formik.errors.description &&
                          formik.touched.description && (
                            <ErrorMessage
                              message={formik.errors.description}
                            />
                          )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default RequestPaymentModal;
