import React from "react";
import { Image } from "antd";

import {
  CalenderButtonResponse,
  CheckButtonResponse,
  EditReportViewButton,
  DeleteButton,
} from "components/button/Buttons";

const ColumnsConfig = ({
  handleRowClick,
  handleRowClickWithoutEvent,
  FindTimeAgo,
  FindCountryCode,
  FALLBACK_IMAGE_URL,
  user,
  openAssignedToDrawer,
  truncateMessage,
  handleTooltipEnter,
  handleMouseEnter,
  setEventCompleted,
  setDeleteRecord,
  isDeleted,
}) => {
  
  return [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (_, __, index) => <span>{index + 1}</span>,
      onCell: (_, entity) => ({
        onClick: (e) => handleRowClick(e, _),
      }),
    },
    {
      title: "Created",
      dataIndex: "time",
      key: "time",
      render: (_, entity) => <span>{FindTimeAgo(entity?.createdAt)}</span>,
      onCell: (_, entity) => ({
        onClick: (e) => handleRowClick(e, _),
      }),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      width: "25%",
      render: (_, entity) => (
        <div className="d-flex cursor-pointer">
          <Image
            className="mb-1"
            width={15}
            preview={false}
            src={
              FindCountryCode(entity?.country)?.code
                ? `assets_modules/flag-icon-css/flags/1x1/${FindCountryCode(
                    entity?.country
                  ).code.toLowerCase()}.svg`
                : FALLBACK_IMAGE_URL
            }
            fallback={FALLBACK_IMAGE_URL}
            
          />
          <div className="align-self-center mx-1">
            <span>
              {entity?.uniqueId} {entity.firstName}{" "}
              {entity.lastName && entity.lastName !== "null"
                ? entity.lastName
                : ""}
            </span>
          </div>
        </div>
      ),
      onCell: (_, entity) => ({
        onClick: (e) => {
          if (!e.target.closest("img")) {
            e.stopPropagation();
          }
          handleRowClickWithoutEvent(_);
        },
      }),
    },
    {
      title: "Assigned To",
      dataIndex: "Assignedto",
      key: "Assignedto",
      width: "25%",
      render: (_, entity) => (
        <div className="flex align-self-center">
          <span>
            {entity?.user?.name_en || entity?.user?.email || "Not Assigned"}
          </span>
          {user?.role === "admin" && (
            <EditReportViewButton
              onClick={(e) => openAssignedToDrawer(e, entity)}
              title="Assigned To"
            />
          )}
        </div>
      ),
      onCell: (_, entity) => ({
        onClick: (e) => handleRowClick(e, _),
      }),
    },
    {
      title: "Client Request",
      dataIndex: "reason",
      key: "reason",
      render: (_, entity) => (
        <span>{truncateMessage(entity.message, 20)}</span>
      ),
      onCell: (_, entity) => ({
        onClick: (e) => handleRowClick(e, _),
      }),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "170px",
      render: (_, entity) => (
        <div className="d-flex align-items-center" style={{ position: "relative" }}>
          <div key={entity.id} onMouseEnter={handleTooltipEnter}>
            <CalenderButtonResponse
              onMouseEnter={(e) => handleMouseEnter(e, entity)}
            />
          </div>
          {entity.status === "open" && (
            <CheckButtonResponse
              onClick={() => {
                setEventCompleted(entity);
                handleRowClick(entity);
              }}
              title="Status"
            />
          )}
          <span>{entity.status}</span>
        </div>
      ),
      onCell: (_, entity) => ({
        onClick: (e) => handleRowClick(e, _),
      }),
    },
    isDeleted !== "isDeleted=true" && {
      title: "Actions",
      dataIndex: "name_de",
      key: "name_de",
      width: "150px",
      render: (_, entity) => (
        <div className="d-flex mx-auto">
          <span className="nav-link">
            <DeleteButton onClick={() => setDeleteRecord(entity)} />
          </span>
        </div>
      ),
      onCell: (_, entity) => ({
        onClick: (e) => handleRowClick(e, _),
      }),
    },
  ].filter(Boolean);
};

export default ColumnsConfig;
